import { useState } from 'react';
import { GET_TASK_SCHEDULES, ITaskSchedulessData, TASK_SCHEDULES_TAKE } from '../../services/taskSchedules/taskScheduleQueries';
import { ITaskSchedule, TaskScheduleType } from '../../services/taskSchedules/taskScheduleTypes';
import useLazyQueryApi from '../useLazyQueryApi';

export interface IDailyTasksQuery {
  fetchTaskSchedules: (type: TaskScheduleType) => Promise<void>,
  refetchCurrentPage: () => Promise<void>,
  fetchPreviousPage: () => Promise<void>,
  fetchNextPage: () => Promise<void>,
  hasNextPage: boolean,
  hasPreviousPage: boolean,
  taskSchedules?: ITaskSchedule[],
  loading: boolean,
  called: boolean,
}

type QueryVariables = {
  where: {
    scheduleType: { eq: TaskScheduleType },
  },
  before?: string,
  after?: string,
  first?: number,
  last?: number,
};

export const useTasksSchedulesQuery = (): IDailyTasksQuery => {
  const [currentVariables, setCurrentVariables] = useState<QueryVariables>();
  const [
    fetchTaskSchedulesQuery,
    { loading, data, called },
  ] = useLazyQueryApi<
    ITaskSchedulessData,
    QueryVariables
  >(GET_TASK_SCHEDULES, { fetchPolicy: 'cache-and-network' });

  const whereClause = (
    type: TaskScheduleType,
    before?: string,
    after?: string,
  ) => (
    {
      where: {
        scheduleType: { eq: type },
      },
      before,
      after,
      first: !before ? TASK_SCHEDULES_TAKE : undefined,
      last: before ? TASK_SCHEDULES_TAKE : undefined,
    }
  );

  const fetchTaskSchedules = async (
    type: TaskScheduleType,
  ): Promise<void> => {
    const variables = whereClause(type);
    setCurrentVariables(variables);

    await fetchTaskSchedulesQuery({ variables });
  };

  const fetchPreviousPage = async (): Promise<void> => {
    if (!currentVariables) return;

    const variables = whereClause(
      currentVariables.where.scheduleType.eq,
      data?.userTaskSchedules?.pageInfo.startCursor,
    );
    setCurrentVariables(variables);
    await fetchTaskSchedulesQuery({ variables });
  };

  const fetchNextPage = async (): Promise<void> => {
    if (!currentVariables) return;

    const variables = whereClause(
      currentVariables.where.scheduleType.eq,
      undefined,
      data?.userTaskSchedules?.pageInfo.endCursor,
    );
    setCurrentVariables(variables);
    await fetchTaskSchedulesQuery({ variables });
  };

  const refetchCurrentPage = async (): Promise<void> => {
    if (!currentVariables) return;
    await fetchTaskSchedulesQuery({ variables: currentVariables });
  };

  return {
    fetchTaskSchedules,
    refetchCurrentPage,
    fetchPreviousPage,
    fetchNextPage,
    hasNextPage: data?.userTaskSchedules?.pageInfo.hasNextPage ?? false,
    hasPreviousPage: data?.userTaskSchedules?.pageInfo.hasPreviousPage ?? false,
    taskSchedules: data?.userTaskSchedules?.nodes,
    loading,
    called,
  };
};
