import { Box } from '@mui/material';
import React, { FC } from 'react';

interface IProps {
  children: React.ReactNode,
}

const ButtonsContainer: FC<IProps> = (props: IProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: 2,
    }}
  >
    {props.children}
  </Box>
);

export default ButtonsContainer;
