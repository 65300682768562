import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { LinkItUrl } from 'react-linkify-it';
import { ITaskDetail } from '../../../services/tasks/taskTypes';
import { printDateTime } from '../../../services/utils/dateTimeService';
import ButtonsContainer from '../../navigation/ButtonsContainer';
import CancelButton from '../../navigation/CancelButton';
import { strings } from './TaskCompletionView.strings';

export interface IProps {
  task: ITaskDetail,
}

const TaskCompletionView: FC<IProps> = (props: IProps) => (
  <div>
    {props.task?.comment && (
    <Box sx={{ mb: 2, wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
      <Typography variant="h6">{strings.comment}</Typography>
      <Typography>
        <LinkItUrl>
          {props.task.comment}
        </LinkItUrl>
      </Typography>
    </Box>
    )}
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6">{strings.completed_on}</Typography>
      <Typography>
        {printDateTime(props.task.completedOn)}
      </Typography>
    </Box>
    <ButtonsContainer>
      <CancelButton />
    </ButtonsContainer>
  </div>
);

export default TaskCompletionView;
