import { Container } from '@mui/material';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import Notifications from '../components/notifications/Notifications';
import strings from './NotificationsPage.strings';

const NotificationsPage: FC = () => (
  <Layout pageName={strings.notifications}>
    <Container>
      <Notifications />
    </Container>
  </Layout>
);

export default NotificationsPage;
