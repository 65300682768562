import { printDate, printTime } from '../utils/dateTimeService';
import { ITask, TaskStatus } from './taskTypes';

export const getTaskTimeString = (task: ITask): string => (
  `${printTime(task.startTime)} - ${printTime(task.endTime)}`
);

export const getCompletedTasksString = (tasks?: ITask[]): string => (
  `${tasks?.filter((t) => t.status === TaskStatus.COMPLETED)?.length ?? 0}/${tasks?.length ?? 0}`
);

export const getTaskMessageString = (task?: ITask): string | undefined => (
  task ? `${task.title}, ${printDate(task.date)}` : undefined
);
