import { Paper } from '@mui/material';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import useQueryApi from '../../hooks/useQueryApi';
import { GET_STUDIO, IStudioData } from '../../services/studios/studioQueries';
import StudioForm from './StudioForm';

const AddStudio: FC = () => {
  const { studioId } = useParams<{ studioId: string }>();
  const { data, loading } = useQueryApi<IStudioData>(GET_STUDIO, {
    variables: { id: studioId },
  });

  if (loading) return null;

  return (
    <Paper
      sx={{
        marginTop: 2,
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '800px',
        marginLeft: 'auto',
        marginRight: 'auto',
        '> h5': {
          marginBottom: 2,
        },
      }}
    >
      <StudioForm studio={data?.studio} />
    </Paper>
  );
};

export default AddStudio;
