import { Container } from '@mui/material';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import AddManagerForm from '../components/managers/AddManagerForm';
import { useQueryString } from '../hooks/queryString/useQueryString';
import strings from './AddManagerPage.strings';

const AddManagerPage: FC = () => {
  const queryString = useQueryString();
  const selectedStudioId = queryString.get('studioid');

  return (
    <Layout pageName={strings.add_manager} showBackButton>
      <Container>
        <AddManagerForm studioId={selectedStudioId ?? undefined} />
      </Container>
    </Layout>
  );
};

export default AddManagerPage;
