import { gql } from '@apollo/client';
import { USER_TASK_DETAIL_FIELDS } from './taskQueries';

export const SAVE_TASK_VALUES = gql`
  mutation saveUserTask($userTaskValues: UserTaskValuesInput!) {
    saveUserTask(userTaskValues: $userTaskValues) {
      ...userTaskDetailFields
    }
  }
  ${USER_TASK_DETAIL_FIELDS}
`;

export const COMPLETE_TASK = gql`
  mutation completeUserTask($userTaskValues: UserTaskValuesInput!) {
    completeUserTask(userTaskValues: $userTaskValues) {
      ...userTaskDetailFields
    }
  }
  ${USER_TASK_DETAIL_FIELDS}
`;
