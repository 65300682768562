import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    message: 'Message',
    send: 'Send',
    cancel: 'Cancel',
    message_to: 'Mesage to {0}',
  },
});
