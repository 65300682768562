import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTaskScheduleDetailsQuery } from '../../../hooks/taskSchedules/useTaskScheduleDetailsQuery';
import TaskForm from './TaskForm';

const UpdateTask: FC = () => {
  const { taskScheduleId } = useParams<{ taskScheduleId: string }>();
  const { taskScheduleDetails, loading } = useTaskScheduleDetailsQuery(taskScheduleId);

  if (loading || !taskScheduleDetails) return null;
  return (
    <TaskForm taskScheduleDetails={taskScheduleDetails} />
  );
};

export default UpdateTask;
