import { useContext } from 'react';
import { FeedbackContext, FeedbackSeverity } from '../../contexts/FeedbackProvider';
import { ADD_ADMIN } from '../../services/users/userMutations';
import { AddAdminInput, IUser } from '../../services/users/userTypes';
import useMutationApi from '../useMutationApi';

export interface IUseAdminMutations {
  addAdmin: (
    addAdminInout: AddAdminInput,
  ) => Promise<void>,
}

export const useAdminMutations = (): IUseAdminMutations => {
  const { showFeedback } = useContext(FeedbackContext);
  const [addAdminMutation] = useMutationApi<
    { addAdmin: IUser },
    { addAdminInput: AddAdminInput }
  >(ADD_ADMIN);

  const addAdmin = async (
    addAdminInput: AddAdminInput,
  ): Promise<void> => {
    await addAdminMutation({
      variables: { addAdminInput },
    });
    showFeedback('Added', FeedbackSeverity.success);
  };

  return {
    addAdmin,
  };
};
