import { gql } from '@apollo/client';
import { USER_FIELDS } from './userQueries';

export const ADD_USER = gql`
  mutation addUser($addUserInput: AddUserInput!) {
    addUser(addUserInput: $addUserInput) {
      ...userFields
    }
  }
  ${USER_FIELDS}
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: UUID!) {
    deleteUser(id: $id)
  }
`;

export const ADD_ADMIN = gql`
  mutation addAdmin($addAdminInput: AddAdminInput!) {
    addAdmin(addAdminInput: $addAdminInput) {
      ...userFields
    }
  }
  ${USER_FIELDS}
`;
