import { Container } from '@mui/material';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import UpdateOrganization from '../components/organizations/UpdateOrganization';
import strings from './UpdateOrganizationPage.strings';

const UpdateOrganizationPage: FC = () => (
  <Layout pageName={strings.update_organization} showBackButton>
    <Container>
      <UpdateOrganization />
    </Container>
  </Layout>
);

export default UpdateOrganizationPage;
