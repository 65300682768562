import {
  ListItem, ListItemText,
} from '@mui/material';
import React, { FC } from 'react';
import { INotification } from '../../services/notifications/notificationTypes';
import { printDateTime } from '../../services/utils/dateTimeService';
import { strings } from './AdminMessageNotification.strings';

export interface IProps {
  notification: INotification,
}

const AdminMessageNotification: FC<IProps> = (props: IProps) => {
  if (!props?.notification) return null;

  const message = props.notification.content.find((x) => x.key === 'message')?.value || '';
  const authorName = props.notification.content.find((x) => x.key === 'authorName')?.value || '';

  return (
    <ListItem>
      <ListItemText
        primary={strings.formatString(
          strings.sent_a_message,
          authorName,
          printDateTime(props.notification.createdOn),
        )}
        secondary={message}
      />
    </ListItem>
  );
};

export default AdminMessageNotification;
