import {
  Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField,
} from '@mui/material';
import { Done } from '@mui/icons-material';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import useQueryApi from '../../hooks/useQueryApi';
import { useUserMutation } from '../../hooks/users/useUserMutations';
import { RoutePaths } from '../../routing/Routes';
import { GET_STUDIOS, IStudiosData } from '../../services/studios/studioQueries';
import { StudioRole } from '../../services/users/userTypes';
import CancelButton from '../navigation/CancelButton';
import { strings } from './AddManagerForm.strings';
import ButtonsContainer from '../navigation/ButtonsContainer';

type Inputs = {
  username: string,
  email: string,
  name: string,
  role: string,
  studioId: string,
}

export interface IProps {
  studioId?: string,
}
const AddManagerForm: FC<IProps> = (props: IProps) => {
  const { push } = useHistory();
  const { loading, data: studioData } = useQueryApi<IStudiosData>(GET_STUDIOS);
  const {
    handleSubmit, control, formState,
  } = useForm<Inputs>();
  const { addUser } = useUserMutation();

  const onSubmit = async (inputs: Inputs) => {
    await addUser({
      ...inputs,
      role: inputs.role as StudioRole,
    });

    push(RoutePaths.managers(inputs.studioId));
  };

  if (loading) return null;

  return (
    <Paper
      sx={{
        marginTop: 2,
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '800px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4} sx={{ mb: 4 }}>

          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={strings.name}
                  variant="outlined"
                  required
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={strings.email}
                  variant="outlined"
                  required
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="username"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={strings.username}
                  variant="outlined"
                  required
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="studioId"
              control={control}
              defaultValue={props.studioId}
              render={({ field }) => (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="add-manager-studio-label">{strings.studio}</InputLabel>
                  <Select
                    {...field}
                    required
                    labelId="add-manager-studio-label"
                    label={strings.studio}
                  >
                    {studioData?.studios?.map((studio) => (
                      <MenuItem
                        key={studio.id}
                        value={studio.id}
                      >
                        {studio.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="role"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="add-manager-role-label">{strings.role}</InputLabel>
                  <Select
                    {...field}
                    required
                    labelId="add-manager-role-label"
                    label={strings.role}
                  >
                    <MenuItem value={StudioRole.STUDIO_MANAGER}>{strings.studio_manager}</MenuItem>
                    <MenuItem value={StudioRole.FRONT_DESK}>{strings.front_desk}</MenuItem>
                    <MenuItem value={StudioRole.FITNESS}>{strings.head_trainer}</MenuItem>
                    <MenuItem value={StudioRole.SALES}>{strings.sales_mamager}</MenuItem>
                    <MenuItem value={StudioRole.REGIONAL_MANAGER}>
                      {strings.regional_manager}
                    </MenuItem>
                    <MenuItem value={StudioRole.VIRTUAL_ASSISTANT}>
                      {strings.virtual_assistant}
                    </MenuItem>
                    <MenuItem value={StudioRole.REMOTE_SALES}>{strings.remote_sales}</MenuItem>
                    <MenuItem value={StudioRole.EXECUTIVE_ASSISTANT}>
                      {strings.executive_assistant}
                    </MenuItem>
                    <MenuItem value={StudioRole.ASSISTANT_MANAGER}>
                      {strings.assistant_manager}
                    </MenuItem>
                    <MenuItem value={StudioRole.FITNESS_CONSULTANT}>
                      {strings.fitenss_consultant}
                    </MenuItem>
                    <MenuItem value={StudioRole.DISTRICT_MANAGER}>
                      {strings.district_manager}
                    </MenuItem>
                    <MenuItem value={StudioRole.SENIOR_TRAINER}>
                      {strings.senior_trainer}
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

        </Grid>

        <ButtonsContainer>
          <CancelButton />
          <Button
            type="submit"
            color="secondary"
            startIcon={<Done />}
            variant="contained"
            disabled={formState.isSubmitting}
          >
            {strings.save}
          </Button>
        </ButtonsContainer>
      </form>
    </Paper>
  );
};

AddManagerForm.defaultProps = {
  studioId: undefined,
};

export default AddManagerForm;
