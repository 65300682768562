export enum StudioRole {
  SALES = 'SALES',
  FITNESS = 'FITNESS',
  FRONT_DESK = 'FRONT_DESK',
  STUDIO_MANAGER = 'STUDIO_MANAGER',
  ADMIN = 'ADMIN',
  ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
  REGIONAL_MANAGER = 'REGIONAL_MANAGER',
  VIRTUAL_ASSISTANT = 'VIRTUAL_ASSISTANT',
  REMOTE_SALES = 'REMOTE_SALES',
  EXECUTIVE_ASSISTANT = 'EXECUTIVE_ASSISTANT',
  ASSISTANT_MANAGER = 'ASSISTANT_MANAGER',
  FITNESS_CONSULTANT = 'FITNESS_CONSULTANT',
  DISTRICT_MANAGER = 'DISTRICT_MANAGER',
  SENIOR_TRAINER = 'SENIOR_TRAINER',
}

export interface IUserDetails {
  name: string,
  email: string,
}

export interface IUser extends IUserDetails{
  id: string,
  studioRole: StudioRole,
  studioId: string,
  deleted: boolean,
  organizationId: string,
}

export type AddUserInput = {
  name: string,
  username: string,
  email: string,
  studioId: string,
  role: StudioRole,
};

export type AddAdminInput = {
  name: string,
  username: string,
  email: string,
  organizationId?: string,
  role: StudioRole.ORGANIZATION_ADMIN | StudioRole.ADMIN,
};
