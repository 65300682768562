import {
  Box,
  Button,
  IconButton,
  List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Add, Edit } from '@mui/icons-material';
import { RoutePaths } from '../../routing/Routes';
import { strings } from './Studios.strings';
import { useStudiosQuery } from '../../hooks/studios/useStudiosQuery';

interface IProps {
  organizationId?: string,
}

const Studios: FC<IProps> = (props: IProps) => {
  const { loading, studios } = useStudiosQuery(props.organizationId);
  const { push } = useHistory();

  if (loading) return null;

  return (
    <Box
      sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 2,
        maxWidth: '800px',
      }}
    >
      {!!props.organizationId && (
        <Box
          sx={{
            marginBottom: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            startIcon={<Add />}
            onClick={() => push(RoutePaths.addStudio(props.organizationId ?? ''))}
          >
            {strings.add_studio}
          </Button>
        </Box>
      )}
      {studios?.length
        ? (
          <Paper>
            <List component="nav">
              {studios?.map((studio) => (
                <ListItem
                  key={studio.id}
                  button
                  onClick={() => push(RoutePaths.managers(studio.id))}
                >
                  <ListItemText primary={studio.name} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="comments"
                      onClick={() => push(RoutePaths.updateStudio(studio.id))}
                    >
                      <Edit />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        )
        : <Typography sx={{ mt: 2 }}>{strings.no_studios}</Typography>}
    </Box>
  );
};

Studios.defaultProps = {
  organizationId: undefined,
};

export default Studios;
