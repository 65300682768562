import { Paper } from '@mui/material';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import StudioForm from './StudioForm';

const AddStudio: FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  return (
    <Paper
      sx={{
        marginTop: 2,
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '800px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <StudioForm organizationId={organizationId} />
    </Paper>
  );
};

export default AddStudio;
