import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';
import React, { FC, useState } from 'react';
import { pickerDateFormat } from '../../services/utils/dateTimeService';
import { strings } from './ClickDatePicker.strings';

export type Props = React.RefAttributes<HTMLDivElement> & {
  onChange: (date: DateTime | null) => void,
  value: string | DateTime,
  label?: string,
}

const ClickDatePicker: FC<Props> = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const { value, label, ...fieldProps } = props;

  return (
    <DatePicker
      {...fieldProps}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      label={label}
      value={typeof value === 'string' ? DateTime.fromISO(value) : value}
      renderInput={(params) => (
        <TextField
          {...params}
          onClick={() => {
            setOpen(true);
          }}
          onKeyDown={(e) => e.preventDefault()}
          sx={{
            '& .MuiInputBase-input': {
              caretColor: 'transparent',
            },
          }}
        />
      )}
      inputFormat={pickerDateFormat}
    />
  );
};

ClickDatePicker.defaultProps = {
  label: strings.select_date,
};

export default ClickDatePicker;
