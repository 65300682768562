import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useDailyTasksQuery } from '../../../hooks/tasks/useDailyTasksQuery';
import TaskListHeading from './TaskListHeading';
import TaskListView from './TaskListView';
import { useQueryString } from '../../../hooks/queryString/useQueryString';
import { RoutePaths } from '../../../routing/Routes';
import { getCompletedTasksString } from '../../../services/tasks/taskService';

export interface IProps {
  userId?: string,
}

const TaskList: FC<IProps> = (props: IProps) => {
  const queryString = useQueryString();
  const history = useHistory();
  const selectedDate = queryString.get('date') ?? DateTime.now().toISODate();

  const {
    tasks, fetchTasks, loading,
  } = useDailyTasksQuery();

  useEffect(() => {
    fetchTasks(selectedDate, props.userId);
  }, [selectedDate]);

  const onDateSelected = (date: string) => {
    history.replace(props.userId
      ? `${RoutePaths.managerTasks(props.userId)}?date=${date}`
      : `${RoutePaths.dailyTasks}?date=${date}`);
  };

  return (
    <>
      <TaskListHeading
        completedTasksMessage={getCompletedTasksString(tasks)}
        selectedDate={selectedDate}
        onDateSelected={onDateSelected}
      />
      {!loading && <TaskListView tasks={tasks} />}
    </>
  );
};

TaskList.defaultProps = {
  userId: undefined,
};

export default TaskList;
