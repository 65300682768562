import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    name: 'Name',
    email: 'Email address',
    username: 'Username',
    save: 'Save',
  },
});
