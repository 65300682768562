import { Box, Paper, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { LinkItUrl } from 'react-linkify-it';
import { useTaskDetailQuery } from '../../../hooks/tasks/useTaskDetailQuery';
import { getTaskMessageString, getTaskTimeString } from '../../../services/tasks/taskService';
import { printDate } from '../../../services/utils/dateTimeService';
import TaskCommentForm from './TaskCommentForm';
import { TaskStatus } from '../../../services/tasks/taskTypes';
import TaskCompletionView from './TaskCompletionView';
import ManagerMenu from '../../managerMenus/ManagerMenu';
import { strings } from './TaskDetail.strings';

export interface IProps {
  id: string,
}

const TaskDetail: FC<IProps> = ({ id }: IProps) => {
  const { loading, fetchTask, task } = useTaskDetailQuery();
  useEffect(() => {
    fetchTask(id);
  }, [id]);

  if (loading || !task) return null;

  const messageText = getTaskMessageString(task);

  return (
    <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
      <ManagerMenu
        userId={task.assignedToId}
        messageText={
          (
            messageText ? strings.formatString(strings.taskMessage, messageText) : undefined
          ) as string
        }
      />
      <Paper
        sx={{
          marginTop: 2,
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          '& > h5': {
            marginBottom: 2,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 2,
          }}
        >
          <Typography variant="h6">{printDate(task.date)}</Typography>
          <Typography variant="h6">{getTaskTimeString(task)}</Typography>
        </Box>
        <Typography variant="h5">{task.title}</Typography>
        <Box sx={{ mb: 2, wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
          <Typography>
            <LinkItUrl>{task.description}</LinkItUrl>
          </Typography>
        </Box>
        {task.status === TaskStatus.COMPLETED
          ? <TaskCompletionView task={task} />
          : <TaskCommentForm task={task} />}
      </Paper>
    </Box>
  );
};

export default TaskDetail;
