import { useContext } from 'react';
import { FeedbackContext, FeedbackSeverity } from '../../contexts/FeedbackProvider';
import { ADD_USER_TASK_SCHEDULE, DELETE_USER_TASK_SCHEDULE, UPDATE_USER_TASK_SCHEDULE } from '../../services/taskSchedules/taskScheduleMutations';
import { ITaskSchedule, UserTaskScheduleInput } from '../../services/taskSchedules/taskScheduleTypes';
import useMutationApi from '../useMutationApi';

export interface IUseTaskMutation {
  addTaskSchedule: (
    taskScheduleInput: UserTaskScheduleInput,
  ) => Promise<void>,
  deleteTaskSchedule: (id: string) => Promise<void>,
  updateTaskSchedule: (
    id: string,
    taskScheduleInput: UserTaskScheduleInput,
  ) => Promise<void>
}

export const useTaskScheduleMutation = (): IUseTaskMutation => {
  const { showFeedback } = useContext(FeedbackContext);
  const [addUserTaskMutation] = useMutationApi<
    { addUserTaskSchedule: ITaskSchedule },
    { userTaskScheduleInput: UserTaskScheduleInput }
  >(ADD_USER_TASK_SCHEDULE);
  const [uppdateUserTaskMutation] = useMutationApi<
    { addUserTaskSchedule: ITaskSchedule },
    { id: string, userTaskScheduleInput: UserTaskScheduleInput }
  >(UPDATE_USER_TASK_SCHEDULE);

  const [deleteUserTaskSchedule] = useMutationApi(DELETE_USER_TASK_SCHEDULE);

  const addTaskSchedule = async (
    taskScheduleInput: UserTaskScheduleInput,
  ): Promise<void> => {
    await addUserTaskMutation({
      variables: { userTaskScheduleInput: taskScheduleInput },
    });
    showFeedback('Saved', FeedbackSeverity.success);
  };

  const deleteTaskSchedule = async (id: string) => {
    await deleteUserTaskSchedule({ variables: { id } });
    showFeedback('Deleted', FeedbackSeverity.success);
  };

  const updateTaskSchedule = async (
    id: string,
    taskScheduleInput: UserTaskScheduleInput,
  ): Promise<void> => {
    await uppdateUserTaskMutation({
      variables: {
        id,
        userTaskScheduleInput: taskScheduleInput,
      },
    });
    showFeedback('Updated', FeedbackSeverity.success);
  };

  return {
    addTaskSchedule,
    deleteTaskSchedule,
    updateTaskSchedule,
  };
};
