import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getRedirectPath } from '../services/auth/loginRedirectService';

const SigninOidcPage: FC = () => {
  const history = useHistory();

  useEffect(() => {
    const redirectPath = getRedirectPath();
    history.replace(redirectPath);
  }, []);

  return null;
};

export default SigninOidcPage;
