import { gql } from '@apollo/client';
import { IStudio } from './studioTypes';

export interface IStudiosData {
  studios: IStudio[],
}

export const STUDIO_FIELDS = gql`
  fragment studioFields on Studio {
      id
      name
    }
`;

export const GET_STUDIOS = gql`
  query ($where: StudioFilterInput){
    studios (where: $where, order: { name: ASC }) {
      ...studioFields
    }
  }
  ${STUDIO_FIELDS}
`;

export interface IStudioData {
  studio: IStudio,
}

export const GET_STUDIO = gql`
  query ($id: UUID!) {
    studio (id: $id) {
      ...studioFields
    }
  }
  ${STUDIO_FIELDS}
`;
