import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
  en: {
    organizations: 'Organizations',
    org_admins: 'Organization Admins',
    studios: 'Studios',
    manage_tasks: 'Manage Tasks',
    tasks: 'Tasks',
    notifications: 'Notifications',
  },
});
