import { useEffect } from 'react';
import { GET_USERS, IUsersData } from '../../services/users/userQueries';
import { IUser, StudioRole } from '../../services/users/userTypes';
import useLazyQueryApi from '../useLazyQueryApi';

export interface IUseOrgAdminsQuery {
  admins?: IUser[],
  loading: boolean,
  called: boolean,
  refetchManagers: () => void,
}

export const useOrgAdminsQuery = (organizationId: string): IUseOrgAdminsQuery => {
  const [fetchOrgAdminsQuery, { loading, data, called }] = useLazyQueryApi<
    IUsersData,
    { where: {
      organizationId: { eq: string },
      deleted: { eq: boolean },
      studioRole: { eq: StudioRole }
    } }
  >(GET_USERS, {
    fetchPolicy: 'cache-and-network',
  });
  const variables = {
    where: {
      organizationId: { eq: organizationId },
      deleted: { eq: false },
      studioRole: { eq: StudioRole.ORGANIZATION_ADMIN },
    },
  };

  useEffect(() => {
    fetchOrgAdminsQuery({ variables });
  }, []);

  const refetchManagers = (): void => {
    fetchOrgAdminsQuery({ variables });
  };

  return {
    admins: data?.users,
    loading,
    called,
    refetchManagers,
  };
};
