import {
  IconButton,
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTaskScheduleMutation } from '../../../../hooks/taskSchedules/useTaskScheduleMutation';
import { useTasksSchedulesQuery } from '../../../../hooks/taskSchedules/useTasksSchedulesQuery';
import { RoutePaths } from '../../../../routing/Routes';
import { getTaskScheduleTimeString } from '../../../../services/taskSchedules/taskScheduleService';
import { TaskScheduleType } from '../../../../services/taskSchedules/taskScheduleTypes';
import { printTime } from '../../../../services/utils/dateTimeService';
import DeleteConfirmation from '../../../widgets/confirmations/DeleteConfirmation';
import LeftRightPagination from '../../../widgets/pagination/LeftRightPagination';
import { strings } from './TaskScheduleList.strings';

export interface IProps {
  taskScheduleType: TaskScheduleType,
}

const TaskScheduleList: FC<IProps> = (props: IProps) => {
  const { push } = useHistory();
  const {
    taskSchedules,
    fetchTaskSchedules,
    refetchCurrentPage,
    fetchPreviousPage,
    fetchNextPage,
    hasNextPage,
    hasPreviousPage,
    loading,
  } = useTasksSchedulesQuery();
  const { deleteTaskSchedule } = useTaskScheduleMutation();
  const [deleteConfirmationId, setDeleteConfirmationId] = useState<string>('');

  useEffect(() => {
    (async () => {
      await fetchTaskSchedules(props.taskScheduleType);
    })();
  }, [
    props.taskScheduleType,
  ]);

  const onDelete = async (id: string) => {
    await deleteTaskSchedule(id);
    await refetchCurrentPage();
  };

  if (loading) return null;

  return (
    <>
      <Paper sx={{ mt: 2, mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: '650px' }} aria-label={props.taskScheduleType}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={(theme) => ({ width: theme.spacing(17) })}
                >
                  {strings.time}
                </TableCell>
                <TableCell>{strings.title}</TableCell>
                <TableCell>{strings[props.taskScheduleType]}</TableCell>
                <TableCell>{}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {taskSchedules?.map((schedule) => (
                <TableRow key={schedule.id}>
                  <TableCell
                    sx={(theme) => ({ width: theme.spacing(17) })}
                  >
                    {`${printTime(schedule.timeFrom)} - ${printTime(schedule.timeTo)}`}
                  </TableCell>
                  <TableCell>
                    {schedule.title}
                  </TableCell>
                  <TableCell>
                    {getTaskScheduleTimeString(schedule)}
                  </TableCell>
                  <TableCell align="right" sx={{ pt: 0, pb: 0 }}>
                    <div>
                      <IconButton
                        aria-label="edit"
                        sx={{ pt: 0, pb: 0 }}
                        onClick={() => push(RoutePaths.updateTask(schedule.id))}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton
                        aria-label="view"
                        sx={{ pt: 0, pb: 0 }}
                        onClick={() => push(RoutePaths.manageTask(schedule.id))}
                      >
                        <Visibility fontSize="small" />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        sx={{ pt: 0, pb: 0 }}
                        onClick={() => setDeleteConfirmationId(schedule.id)}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <LeftRightPagination
          hasPreviousPage={hasPreviousPage}
          hasNextPage={hasNextPage}
          onPrevious={fetchPreviousPage}
          onNext={fetchNextPage}
        />
      </Paper>
      <DeleteConfirmation
        open={!!deleteConfirmationId}
        onClose={() => setDeleteConfirmationId('')}
        onDelete={() => {
          onDelete(deleteConfirmationId);
          setDeleteConfirmationId('');
        }}
        subjectTitle={strings.the_schedule}
        subject={strings.formatString(
          strings.all_future,
          taskSchedules?.find((t) => t.id === deleteConfirmationId)?.title || '',
        ) as string}
      />
    </>
  );
};

export default TaskScheduleList;
