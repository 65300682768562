import { useContext } from 'react';
import { FeedbackContext, FeedbackSeverity } from '../../contexts/FeedbackProvider';
import { ADD_USER } from '../../services/users/userMutations';
import { AddUserInput, IUser } from '../../services/users/userTypes';
import useMutationApi from '../useMutationApi';

export interface IUseUserMutation {
  addUser: (
    addUserInput: AddUserInput,
  ) => Promise<void>,
}

export const useUserMutation = (): IUseUserMutation => {
  const { showFeedback } = useContext(FeedbackContext);
  const [addUserMutation] = useMutationApi<
    { addUser: IUser },
    { addUserInput: AddUserInput }
  >(ADD_USER);

  const addUser = async (
    addUserInput: AddUserInput,
  ): Promise<void> => {
    await addUserMutation({
      variables: { addUserInput },
    });
    showFeedback('Added', FeedbackSeverity.success);
  };

  return {
    addUser,
  };
};
