import React, { FunctionComponent } from 'react';
import {
  Dialog, DialogContent, DialogContentText, DialogActions, Button, DialogTitle,
} from '@mui/material';
import { strings } from './ConfirmationDialog.strings';

export interface IProps {
    open: boolean,
    title: string,
    text: string,
    onClose: () => void,
    cancelButtonLabel?: string,
    onConfirm: () => void,
    confirmButtonLabel: string
}

const ConfirmationDialog: FunctionComponent<IProps> = (props: IProps) => (
  <Dialog open={props.open} onClose={props.onClose}>
    <DialogTitle>{props.title}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {props.text}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose} color="primary" autoFocus>
        {props.cancelButtonLabel ? props.cancelButtonLabel : strings.cancel}
      </Button>
      <Button onClick={props.onConfirm} color="secondary">
        {props.confirmButtonLabel}
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmationDialog.defaultProps = {
  cancelButtonLabel: undefined,
};

export default ConfirmationDialog;
