import { gql } from '@apollo/client';
import { NOTIFICATION_FIELDS } from './notificationQueries';

export const MARK_AS_READ = gql`
  mutation {
    markAsRead {
      id
      hasUnread
    }
  }
`;

export const ADD_NOTIFICATION = gql`
  mutation addNotification($addNotificationInput: AddNotificationInput!) {
    addNotification(addNotificationInput: $addNotificationInput) {
      ...notificationFields
    }
  }
  ${NOTIFICATION_FIELDS}
`;
