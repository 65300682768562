import {
  Box,
  Button, Dialog, Paper, TextField, Typography,
} from '@mui/material';
import { Close, Send } from '@mui/icons-material';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNotificationsMutation } from '../../hooks/notifications/useNotificationsMutation';
import { IUser } from '../../services/users/userTypes';
import { strings } from './ManagerMessageDialog.strings';
import ButtonsContainer from '../navigation/ButtonsContainer';

export interface IProps {
  user?: IUser,
  open: boolean,
  onClose: () => void,
  initialText?: string,
}

type Inputs = {
  message: string,
}

const ManagerMessageDialog: FC<IProps> = (props: IProps) => {
  const {
    handleSubmit, control, formState,
  } = useForm<Inputs>();

  const { addNotification } = useNotificationsMutation();

  const onSubmit = async (inputs: Inputs) => {
    if (props.user) {
      await addNotification({
        ...inputs,
        recipientId: props.user?.id,
      });
      props.onClose();
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
    >
      <Paper sx={{ p: 2, minWidth: '30rem' }}>
        <Typography variant="h6">
          {strings.formatString(strings.message_to, props.user?.name || '')}
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box sx={{ mt: 2, mb: 2 }}>
            <Controller
              name="message"
              control={control}
              defaultValue={props.initialText ?? ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={strings.message}
                  variant="outlined"
                  multiline
                  minRows={3}
                />
              )}
            />
          </Box>
          <ButtonsContainer>
            <Button
              color="primary"
              startIcon={<Close />}
              variant="outlined"
              onClick={props.onClose}
            >
              {strings.cancel}
            </Button>
            <Button
              type="submit"
              color="secondary"
              startIcon={<Send />}
              variant="contained"
              disabled={formState.isSubmitting}
            >
              {strings.send}
            </Button>
          </ButtonsContainer>
        </form>

      </Paper>

    </Dialog>
  );
};

ManagerMessageDialog.defaultProps = {
  user: undefined,
  initialText: undefined,
};

export default ManagerMessageDialog;
