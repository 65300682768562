import Amplify, { Auth } from 'aws-amplify';
import { AuthUser } from './AuthUser';
import config from '../../config';
import { User } from './User';

export const initAuth = (): void => {
  Amplify.configure({
    Auth: { ...config.awsConfigAuth },
  });
};

export const logIn = async (): Promise<void> => {
  await Auth.federatedSignIn();
};

export const logOut = async (): Promise<void> => {
  await Auth.signOut();
};

export const getUser = async (): Promise<User | null> => {
  try {
    const user: AuthUser = await Auth.currentAuthenticatedUser();
    if (!user?.signInUserSession?.idToken?.jwtToken) return null;

    return {
      email: user.signInUserSession.idToken.payload.email,
      phoneNumber: user.signInUserSession.idToken.payload.phone_number,
      userId: user.signInUserSession.idToken.payload.sub,
      token: user.signInUserSession.idToken.jwtToken,
    };
  } catch {
    return null;
  }
};

export const acceptLoginRedirect = async (): Promise<boolean> => Promise.resolve(true);

export const getAccessToken = async (): Promise<string | null> => {
  const user = await getUser();

  if (!user) return null;
  return user?.token;
};

export const reLogin = async (): Promise<void> => {
  const user = await getUser();
  if (user) {
    await logOut();
  } else {
    await logIn();
  }
};
