import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import AppQuery from './components/queries/Queries';
import { FeedbackProvider } from './contexts/FeedbackProvider';
import ProgressIndicatorProvider from './contexts/ProgressIndicatorProvider';
import Routes from './routing/Routes';
import { initAuth } from './services/auth/authService';
import MainTheme from './components/themes/MainTheme';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let module: any;

const AuthInit: React.FC = () => {
  React.useEffect(() => {
    initAuth();
  }, []);

  return null;
};

ReactDOM.render(
  <div>
    <MainTheme>
      <AuthInit />
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <FeedbackProvider>
          <ProgressIndicatorProvider>
            <AppQuery>
              <Routes />
            </AppQuery>
          </ProgressIndicatorProvider>
        </FeedbackProvider>
      </LocalizationProvider>
    </MainTheme>
  </div>,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept();
}
