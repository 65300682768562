import { useContext } from 'react';
import { FeedbackContext, FeedbackSeverity } from '../../contexts/FeedbackProvider';
import { ADD_NOTIFICATION, MARK_AS_READ } from '../../services/notifications/notificationMutations';
import { AddNotificationInput, INotification, IUnreadNotificationsCheck } from '../../services/notifications/notificationTypes';
import useMutationApi from '../useMutationApi';

export interface IUseNotificationsMutation {
  markAsRead: () => Promise<void>,
  addNotification: (addNotificationInput: AddNotificationInput) => Promise<void>
}

export const useNotificationsMutation = (): IUseNotificationsMutation => {
  const { showFeedback } = useContext(FeedbackContext);
  const [amarkAsReadMutation] = useMutationApi<
    { markAsRead: IUnreadNotificationsCheck }
  >(MARK_AS_READ);

  const [addNotificationMutation] = useMutationApi<
    { addNotification: INotification },
    { addNotificationInput: AddNotificationInput }
  >(ADD_NOTIFICATION);

  const markAsRead = async (): Promise<void> => {
    await amarkAsReadMutation();
  };

  const addNotification = async (addNotificationInput: AddNotificationInput): Promise<void> => {
    await addNotificationMutation({
      variables: { addNotificationInput },
    });
    showFeedback('Message sent', FeedbackSeverity.success);
  };

  return {
    markAsRead,
    addNotification,
  };
};
