import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    title: 'Title',
    edit: 'EDIT',
    description: 'Description',
    studio: 'Studio',
    role: 'Role',
    days_of_week: 'Days Of Week',
    day_of_month: 'Day Of Month',
    one_off: 'One Off',
    schedule_date: 'Schedule Date',
    all: 'All',
    FRONT_DESK: 'Front Desk',
    FITNESS: 'Head Trainer',
    SALES: 'Sales and Member Manager',
    STUDIO_MANAGER: 'Studio Manager',
    REGIONAL_MANAGER: 'Regional Manager',
    VIRTUAL_ASSISTANT: 'Virtual Assistant',
    REMOTE_SALES: 'Remote Sales',
    EXECUTIVE_ASSISTANT: 'Executive Assistant',
    ASSISTANT_MANAGER: 'Assistant Manager',
    FITNESS_CONSULTANT: 'Fitness Consultant',
    DISTRICT_MANAGER: 'District Manager',
    SENIOR_TRAINER: 'Senior Trainer',
  },
});
