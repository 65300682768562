import {
  Button, Grid, Paper, TextField,
} from '@mui/material';
import { Done } from '@mui/icons-material';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useAdminMutations } from '../../hooks/users/useAdminMutations';
import { RoutePaths } from '../../routing/Routes';
import { StudioRole } from '../../services/users/userTypes';
import CancelButton from '../navigation/CancelButton';
import { strings } from './AddAdminForm.strings';
import ButtonsContainer from '../navigation/ButtonsContainer';

type Inputs = {
  username: string,
  email: string,
  name: string,
}

export interface IProps {
  organizationId: string,
}
const AddAdminForm: FC<IProps> = (props: IProps) => {
  const { push } = useHistory();
  const {
    handleSubmit, control, formState,
  } = useForm<Inputs>();
  const { addAdmin } = useAdminMutations();

  const onSubmit = async (inputs: Inputs) => {
    await addAdmin({
      ...inputs,
      role: StudioRole.ORGANIZATION_ADMIN,
      organizationId: props.organizationId,
    });

    push(RoutePaths.orgAdmins(props.organizationId));
  };

  return (
    <Paper
      sx={{
        marginTop: 2,
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '800px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4} sx={{ mb: 4 }}>

          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={strings.name}
                  variant="outlined"
                  required
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={strings.email}
                  variant="outlined"
                  required
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="username"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={strings.username}
                  variant="outlined"
                  required
                />
              )}
            />
          </Grid>

        </Grid>

        <ButtonsContainer>
          <CancelButton />
          <Button
            type="submit"
            color="secondary"
            startIcon={<Done />}
            variant="contained"
            disabled={formState.isSubmitting}
          >
            {strings.save}
          </Button>
        </ButtonsContainer>
      </form>
    </Paper>
  );
};

export default AddAdminForm;
