import { dayOfWeekShortDisplay, printDate } from '../utils/dateTimeService';
import { ITaskSchedule, TaskScheduleType } from './taskScheduleTypes';

export const getTaskScheduleTimeString = (taskSchedule: ITaskSchedule): string => {
  switch (taskSchedule.scheduleType) {
    case TaskScheduleType.ONEOFF:
      return printDate(taskSchedule.oneOffDate);
    case TaskScheduleType.WEEKLY:
      return taskSchedule.daysOfWeek
        ?.map((d) => dayOfWeekShortDisplay(d))
        .join(', ');
    default:
      return taskSchedule.dayOfMonth?.toString() || '';
  }
};
