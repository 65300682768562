import {
  DocumentNode, useLazyQuery, LazyQueryHookOptions, QueryTuple, OperationVariables,
} from '@apollo/client';
import { useContext, useEffect } from 'react';
import { FeedbackContext, FeedbackSeverity } from '../contexts/FeedbackProvider';
import useProgressIndicator from './useProgressIndicator';

export default function useLazyQueryApi<
  TData = unknown,
  TVariables = OperationVariables
>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<TData, TVariables>,
): QueryTuple<TData, TVariables> {
  const queryResult = useLazyQuery(query, options);
  const { showFeedback } = useContext(FeedbackContext);
  const { progressStarted, progressEnded } = useProgressIndicator();

  const { error: queryError } = queryResult[1];

  useEffect(() => {
    if (queryError) {
      showFeedback(queryError.message, FeedbackSeverity.error);
    }
  }, [
    queryError,
  ]);

  useEffect(() => {
    if (queryResult[1]?.loading) {
      progressStarted();
    } else {
      progressEnded();
    }
  }, [
    queryResult[1]?.loading,
  ]);

  return queryResult;
}
