import {
  IconButton, ListItem, ListItemSecondaryAction, ListItemText,
} from '@mui/material';
import { Visibility } from '@mui/icons-material';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../routing/Routes';
import { INotification } from '../../services/notifications/notificationTypes';
import { printDate, printDateTime } from '../../services/utils/dateTimeService';
import { strings } from './TaskCompletedNotification.strings';

export interface IProps {
  notification: INotification,
}

const TaskCompletedNotification: FC<IProps> = (props: IProps) => {
  const { push } = useHistory();
  if (!props?.notification) return null;

  const taskId = props.notification.content.find((x) => x.key === 'taskId')?.value || '';
  const title = props.notification.content.find((x) => x.key === 'title')?.value || '';
  const date = props.notification.content.find((x) => x.key === 'date')?.value || '';
  const completedOn = props.notification.content.find((x) => x.key === 'completedOn')?.value || '';
  const assignedToName = props.notification.content.find((x) => x.key === 'assignedToName')?.value || '';

  return (
    <ListItem>
      <ListItemText
        primary={strings.formatString(
          strings.has_completed,
          assignedToName,
          printDateTime(props.notification.createdOn),
        )}
        secondary={strings.formatString(
          strings.task_completed,
          title,
          printDate(date),
          printDateTime(completedOn),
        )}
      />
      {taskId && (
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="view"
          onClick={() => push(RoutePaths.taskDetail(taskId))}
        >
          <Visibility />
        </IconButton>
      </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default TaskCompletedNotification;
