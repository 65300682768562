import { Container } from '@mui/material';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import TaskDetail from '../components/tasks/taskDetail/TaskDetail';
import strings from './TaskDetailPage.strings';

const TaskDetailPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <Layout pageName={strings.task_detail} showBackButton>
      <Container>
        <TaskDetail id={id} />
      </Container>
    </Layout>
  );
};

export default TaskDetailPage;
