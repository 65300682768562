import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { FC, useState } from 'react';
import { pickerTimeFormat } from '../../services/utils/dateTimeService';

export type Props = React.RefAttributes<HTMLDivElement> & {
  onChange: (date: DateTime | null) => void,
  value: string | DateTime,
  label: string,
  minuteStep?: number,
}

const ClickTimePicker: FC<Props> = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const {
    value, label, minuteStep, ...fieldProps
  } = props;

  return (
    <TimePicker
      {...fieldProps}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      label={label}
      minutesStep={minuteStep}
      value={typeof value === 'string' ? DateTime.fromISO(value) : value}
      renderInput={(params) => (
        <TextField
          {...params}
          onClick={() => {
            setOpen(true);
          }}
          onKeyDown={(e) => e.preventDefault()}
          sx={{
            '& .MuiInputBase-input': {
              caretColor: 'transparent',
            },
          }}
        />
      )}
      inputFormat={pickerTimeFormat}
    />
  );
};

ClickTimePicker.defaultProps = {
  minuteStep: 5,
};

export default ClickTimePicker;
