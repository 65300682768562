import { Container } from '@mui/material';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import ManageTasks from '../components/tasks/manage/ManageTasks';
import strings from './ManageTasksPage.strings';

const ManageTasksPage: FC = () => (
  <Layout pageName={strings.manage_tasks} showBackButton>
    <Container>
      <ManageTasks />
    </Container>
  </Layout>
);

export default ManageTasksPage;
