import { Container } from '@mui/material';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import Managers from '../components/managers/Managers';
import useQueryApi from '../hooks/useQueryApi';
import { GET_STUDIO, IStudioData } from '../services/studios/studioQueries';
import strings from './ManagersPage.strings';

const ManagersPage: FC = () => {
  const { studioId } = useParams<{ studioId: string }>();
  const { data, loading } = useQueryApi<IStudioData>(GET_STUDIO, {
    variables: { id: studioId },
  });

  return (
    <Layout
      pageName={strings.formatString(strings.managers, data?.studio.name || '').toString()}
      showBackButton
    >
      <Container>
        {!loading && <Managers />}
      </Container>
    </Layout>
  );
};

export default ManagersPage;
