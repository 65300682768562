import { Button, TextField } from '@mui/material';
import { Done } from '@mui/icons-material';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useOrganizationMutations } from '../../hooks/organizations/useOrganizationMutations';
import { RoutePaths } from '../../routing/Routes';
import { IOrganization } from '../../services/organizations/organizationTypes';
import CancelButton from '../navigation/CancelButton';
import { strings } from './OrganizationForm.strings';
import ButtonsContainer from '../navigation/ButtonsContainer';

type Inputs = {
  name: string,
}

export interface IProps {
  organization?: IOrganization,
}

const OrganizationForm: FC<IProps> = (props: IProps) => {
  const { push } = useHistory();
  const {
    handleSubmit, control, formState,
  } = useForm<Inputs>();
  const { addOrganization, updateOrganization } = useOrganizationMutations();

  const onSubmit = async (data: Inputs) => {
    if (!props.organization) {
      await addOrganization(data.name);
      push(RoutePaths.organizations);
    } else {
      await updateOrganization({
        id: props.organization.id,
        name: data.name,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        defaultValue={props?.organization?.name ?? ''}
        render={({ field }) => (
          <TextField
            sx={{ width: '100%', mb: 4 }}
            {...field}
            label={strings.name}
            variant="outlined"
            required
          />
        )}
      />
      <ButtonsContainer>
        <CancelButton />
        <Button
          type="submit"
          color="secondary"
          startIcon={<Done />}
          variant="contained"
          disabled={formState.isSubmitting}
        >
          {!props.organization ? strings.save : strings.update}
        </Button>
      </ButtonsContainer>
    </form>
  );
};

OrganizationForm.defaultProps = {
  organization: undefined,
};

export default OrganizationForm;
