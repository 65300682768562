import {
  Box,
  Button, Paper, Tab, Tabs,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../routing/Routes';
import { TaskScheduleType } from '../../../services/taskSchedules/taskScheduleTypes';
import TabPanel from '../../tabs/TabPanel';
import { strings } from './ManageTasks.strings';
import TaskScheduleList from './schedulesList/TaskScheduleList';

const ManageTasks: FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { push } = useHistory();

  return (
    <Box sx={{ mt: 2 }}>
      <Box
        sx={{
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          color="primary"
          variant="outlined"
          startIcon={<Add />}
          onClick={() => push(RoutePaths.addTask)}
        >
          {strings.schedule_task}
        </Button>
      </Box>
      <Paper sx={{ mt: 2 }}>
        <Tabs
          value={selectedTab}
          textColor="primary"
          onChange={(_, newValue) => setSelectedTab(newValue)}
        >
          <Tab label={strings.days_of_week} />
          <Tab label={strings.day_of_month} />
          <Tab label={strings.one_off} />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <TaskScheduleList taskScheduleType={TaskScheduleType.WEEKLY} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <TaskScheduleList taskScheduleType={TaskScheduleType.MONTHLY} />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <TaskScheduleList taskScheduleType={TaskScheduleType.ONEOFF} />
        </TabPanel>
      </Paper>
    </Box>
  );
};

export default ManageTasks;
