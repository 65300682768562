import {
  Box,
  Button,
  IconButton,
  List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Typography,
} from '@mui/material';
import { Add, RemoveCircleOutline } from '@mui/icons-material';
import React, { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useMutationApi from '../../hooks/useMutationApi';
import useQueryApi from '../../hooks/useQueryApi';
import { useStudioManagersQuery } from '../../hooks/users/useStudioManagersQuery';
import { RoutePaths } from '../../routing/Routes';
import { DELETE_USER } from '../../services/users/userMutations';
import { GET_MY_PROFILE, IMeData } from '../../services/users/userQueries';
import { isOrganizationAdmin } from '../../services/users/userService';
import DeleteConfirmation from '../widgets/confirmations/DeleteConfirmation';
import { strings } from './Managers.strings';

const Managers: FC = () => {
  const { studioId } = useParams<{ studioId: string }>();
  const { push } = useHistory();
  const { loading, studioManagers, refetchManagers } = useStudioManagersQuery(studioId);
  const { data: myProfile, loading: meLoading } = useQueryApi<IMeData>(GET_MY_PROFILE);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [userIdToDelete, setUserIdToDelete] = useState<string>();
  const [deleteUser] = useMutationApi(DELETE_USER);

  if (loading || meLoading || !studioManagers) return null;

  const onUserDelete = async () => {
    await deleteUser({ variables: { id: userIdToDelete } });
    setDeleteOpen(false);
    refetchManagers();
  };

  return (
    <>
      <Box
        sx={{
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 2,
          maxWidth: '800px',
        }}
      >
        <Box
          sx={{
            marginBottom: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            startIcon={<Add />}
            onClick={() => push(`${RoutePaths.addManager}?studioid=${studioId}`)}
          >
            {strings.add_manager}
          </Button>
        </Box>
        {!studioManagers.length
          ? <Typography sx={{ mt: 2 }}>{strings.no_managers}</Typography>
          : (
            <Paper>
              <List component="nav">
                {studioManagers?.map((studioManager) => (
                  <ListItem
                    key={studioManager.id}
                    button
                    onClick={
                      isOrganizationAdmin(myProfile?.me)
                        ? () => push(RoutePaths.managerTasks(studioManager.id))
                        : undefined
                      }
                  >
                    <ListItemText primary={studioManager.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="remove-user"
                        component="span"
                        onClick={() => {
                          setUserIdToDelete(studioManager.id);
                          setDeleteOpen(true);
                        }}
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
      </Box>
      <DeleteConfirmation
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDelete={onUserDelete}
        subject={strings.delete}
        subjectTitle={strings.delete_user}
      />
    </>
  );
};

export default Managers;
