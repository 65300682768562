import { GET_TASK_DETAIL, ITaskDetailData } from '../../services/tasks/taskQueries';
import { ITaskDetail } from '../../services/tasks/taskTypes';
import useLazyQueryApi from '../useLazyQueryApi';

export interface ITaskDetailQuery {
  fetchTask: (id: string) => void,
  task?: ITaskDetail,
  loading: boolean,
  called: boolean,
}

export const useTaskDetailQuery = (): ITaskDetailQuery => {
  const [
    fetchTasksQuery,
    { loading, data, called },
  ] = useLazyQueryApi<
    ITaskDetailData,
    { id: string }
  >(GET_TASK_DETAIL);

  const fetchTask = (id: string) => {
    fetchTasksQuery({ variables: { id } });
  };

  return {
    fetchTask,
    task: data?.userTask,
    loading,
    called,
  };
};
