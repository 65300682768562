import { Paper } from '@mui/material';
import React, { FC } from 'react';
import OrganizationForm from './OrganizationForm';

const AddOrganization: FC = () => (
  <Paper
    sx={{
      padding: 4,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '800px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 2,
    }}
  >
    <OrganizationForm />
  </Paper>
);

export default AddOrganization;
