import {
  AppBar, Box, Divider, Drawer, IconButton, Toolbar, Typography,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import MenuList from './MenuList';
import UserButton from './UserButton';

export type Props = RouteComponentProps & {
  showBackButton?: boolean,
  pageName?: string,
};

const leftNavWidth = 220;

const NavigationBar: FC<Props> = (props: Props) => {
  const navigateBack = () => {
    const { history } = props;
    history.goBack();
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          flexGrow: 1,
          ml: leftNavWidth,
          width: `calc(100% - ${leftNavWidth}px)`,
        }}
      >
        <Toolbar>
          {props.showBackButton
            && (
            <IconButton
              color="inherit"
              aria-label="back"
              onClick={navigateBack}
            >
              <ArrowBackIcon />
            </IconButton>
            )}
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {props.pageName}
          </Typography>
          <UserButton />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: leftNavWidth,
          flexShrink: 0,
          '> div': {
            width: leftNavWidth,
          },
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 8px',
            ...theme.mixins.toolbar,
          })}
        >
          <Typography variant="h5" color="primary">F45 Training</Typography>
        </Box>
        <Divider />
        <MenuList />
      </Drawer>
    </>
  );
};

NavigationBar.defaultProps = {
  showBackButton: undefined,
  pageName: undefined,
};

export default NavigationBar;
