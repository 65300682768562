import { Paper } from '@mui/material';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import useQueryApi from '../../hooks/useQueryApi';
import { GET_ORGANIZATION, IOrganizationData } from '../../services/organizations/organizationQueries';
import OrganizationForm from './OrganizationForm';

const AddStudio: FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, loading } = useQueryApi<IOrganizationData>(GET_ORGANIZATION, {
    variables: { id: organizationId },
  });

  if (loading) return null;

  return (
    <Paper
      sx={{
        marginTop: 2,
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '800px',
        marginLeft: 'auto',
        marginRight: 'auto',
        '> h5': {
          marginBottom: 2,
        },
      }}
    >
      <OrganizationForm organization={data?.organization} />
    </Paper>
  );
};

export default AddStudio;
