import { List, Paper, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useNotificationsMutation } from '../../hooks/notifications/useNotificationsMutation';
import { useNotificationsQuery } from '../../hooks/notifications/useNotificationsQuery';
import { NotificationType } from '../../services/notifications/notificationTypes';
import LeftRightPagination from '../widgets/pagination/LeftRightPagination';
import AdminMessageNotification from './AdminMessageNotification';
import { strings } from './Notifications.strings';
import TaskCompletedNotification from './TaskCompletedNotification';

const Notifications: FC = () => {
  const {
    notifications,
    fetchNotifications,
    loading,
    hasPreviousPage,
    hasNextPage,
    fetchPreviousPage,
    fetchNextPage,
  } = useNotificationsQuery();
  const { markAsRead } = useNotificationsMutation();

  useEffect(() => {
    (async () => {
      await fetchNotifications();
      await markAsRead();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (notifications?.length) {
        await markAsRead();
      }
    })();
  }, [notifications]);

  if (loading) return null;

  if (!notifications?.length) {
    return (
      <Typography sx={{ mt: 2 }}>{strings.no_notifications}</Typography>
    );
  }

  return (
    <Paper sx={{ mt: 2 }}>
      <List>
        {notifications?.map((notification) => {
          switch (notification.notificationType) {
            case NotificationType.TASK_COMPLETED:
              return (
                <TaskCompletedNotification
                  key={notification.id}
                  notification={notification}
                />
              );
            case NotificationType.ADMIN_MESSAGE:
              return (
                <AdminMessageNotification
                  key={notification.id}
                  notification={notification}
                />
              );
            default:
              return null;
          }
        })}
      </List>
      <LeftRightPagination
        hasPreviousPage={hasPreviousPage}
        hasNextPage={hasNextPage}
        onPrevious={fetchPreviousPage}
        onNext={fetchNextPage}
      />
    </Paper>
  );
};

export default Notifications;
