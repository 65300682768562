import { DateTime } from 'luxon';
import { strings } from './dateTimeService.strings';

export const pickerDateFormat = 'MMMM dd';
export const pickerTimeFormat = 'h:mm a';

export const printDate = (value?: string): string => {
  if (!value) return '-';

  const date = DateTime.fromISO(value);

  return date.toLocaleString(DateTime.DATE_FULL);
};

export const printDateWithoutZone = (value?: string): string => {
  if (!value) return '-';

  let dateValue = value;
  if (dateValue.toUpperCase().endsWith('Z')) {
    dateValue = dateValue.slice(0, -1);
  }
  const date = DateTime.fromISO(dateValue);

  return date.toLocaleString(DateTime.DATE_FULL);
};

export const printDateTime = (value?: string): string => {
  if (!value) return '-';

  const date = DateTime.fromISO(value);

  return date.toLocaleString(DateTime.DATETIME_FULL);
};

export const printTime = (value?: string): string => {
  if (!value) return '-';
  const isoTime = DateTime.fromISO(value);
  return isoTime.toFormat(pickerTimeFormat);
};

export const hours = [
  '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
  '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
  '21', '22', '23', '24',
];

export const minutes = ['00', '15', '30', '45'];

export enum DayOfWeek {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY'
}

export const daysOfWeek = [
  DayOfWeek.SUNDAY,
  DayOfWeek.MONDAY,
  DayOfWeek.TUESDAY,
  DayOfWeek.WEDNESDAY,
  DayOfWeek.THURSDAY,
  DayOfWeek.FRIDAY,
  DayOfWeek.SATURDAY,
];

export const dayOfWeekDisplay = (dayOfWeek: DayOfWeek): string => (
  strings[dayOfWeek]
);

export const dayOfWeekShortDisplay = (dayOfWeek: DayOfWeek): string => (
  strings[`${dayOfWeek}_short`]
);

export const displayTimeToDateTime = (time?: string): DateTime | undefined => {
  if (!time) return undefined;

  try {
    return DateTime.fromFormat(time, 'hh:mm');
  } catch {
    return undefined;
  }
};
