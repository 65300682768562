import { IStudio } from '../studios/studioTypes';
import { IUser, StudioRole } from '../users/userTypes';
import { DayOfWeek } from '../utils/dateTimeService';

export enum TaskScheduleType {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ONEOFF = 'ONEOFF',
}

export interface ITaskScheduleDetail {
  id: string,
  title: string,
  description: string,
  roles: StudioRole[],
  daysOfWeek: DayOfWeek[],
  dayOfMonth?: number,
  oneOffDate?: string,
  scheduleType: TaskScheduleType,
  timeFrom?: string,
  timeTo?: string,
  createdBy: IUser,
  createdOn: string,
  userTaskScheduleStudios: {
    studio: IStudio,
  }[],
}

export interface ITaskSchedule {
  id: string,
  title: string,
  description: string,
  roles: StudioRole[],
  daysOfWeek: DayOfWeek[],
  dayOfMonth?: number,
  oneOffDate?: string,
  scheduleType: TaskScheduleType,
  timeFrom?: string,
  timeTo?: string,
  createdOn: string,
  userTaskScheduleStudios: {
    studio: IStudio,
  }
}

export type UserTaskScheduleInput = {
  title: string,
  description: string,
  studios: string[],
  roles: StudioRole[],
  daysOfWeek: DayOfWeek[],
  dayOfMonth: number,
  oneOffDate: Date,
  scheduleType: TaskScheduleType,
  timeFrom: string,
  timeTo: string,
};
