import React, {
  createContext, FC, useReducer, PropsWithChildren,
} from 'react';
import update from 'immutability-helper';

type Action = { type: 'progressStarted', key: string }
            | { type: 'progressEnded', key: string };
type Dispatch = (action: Action) => void;
type State = { progressKeys: string[] };

export const ProgressIndicatorContext = createContext<Dispatch | undefined>(undefined);
export const ProgressIndicatotStateContext = createContext<boolean | undefined>(undefined);

const progressIndicatorReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'progressStarted': {
      if (state.progressKeys.includes(action.key)) return state;
      return update(state, { progressKeys: { $push: [action.key] } });
    }
    case 'progressEnded': {
      const index = state.progressKeys.indexOf(action.key);
      if (index < 0) return state;
      return update(state, { progressKeys: { $splice: [[index, 1]] } });
    }
    default:
      return state;
  }
};

const ProgressIndicatorProvider: FC = (props: PropsWithChildren<unknown>) => {
  const [state, dispatch] = useReducer(
    progressIndicatorReducer,
        { progressKeys: [] } as State,
  );

  const showingProgress = !!state?.progressKeys.length;

  return (
    <ProgressIndicatotStateContext.Provider value={showingProgress}>
      <ProgressIndicatorContext.Provider value={dispatch}>
        {props.children}
      </ProgressIndicatorContext.Provider>
    </ProgressIndicatotStateContext.Provider>
  );
};

export default ProgressIndicatorProvider;
