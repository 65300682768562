import { Button, TextField } from '@mui/material';
import { Done } from '@mui/icons-material';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useStudioMutation } from '../../hooks/studios/useStudioMutations';
import { RoutePaths } from '../../routing/Routes';
import { IStudio } from '../../services/studios/studioTypes';
import CancelButton from '../navigation/CancelButton';
import { strings } from './StudioForm.strings';
import ButtonsContainer from '../navigation/ButtonsContainer';

type Inputs = {
  name: string,
}

export interface IProps {
  studio?: IStudio,
  organizationId?: string,
}

const StudioForm: FC<IProps> = (props: IProps) => {
  const { replace } = useHistory();
  const {
    handleSubmit, control, formState,
  } = useForm<Inputs>();
  const { addStudio, updateStudio } = useStudioMutation();

  const onSubmit = async (data: Inputs) => {
    if (!props.studio) {
      if (props.organizationId) {
        await addStudio(data.name, props.organizationId);
        replace(`${RoutePaths.studios}?organizationid=${props.organizationId}`);
      }
    } else {
      await updateStudio({
        id: props.studio.id,
        name: data.name,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        defaultValue={props?.studio?.name ?? ''}
        render={({ field }) => (
          <TextField
            sx={{ width: '100%', mb: 4 }}
            {...field}
            label={strings.name}
            variant="outlined"
            required
          />
        )}
      />
      <ButtonsContainer>
        <CancelButton />
        <Button
          type="submit"
          color="secondary"
          startIcon={<Done />}
          variant="contained"
          disabled={formState.isSubmitting}
        >
          {!props.studio ? strings.save : strings.update}
        </Button>
      </ButtonsContainer>
    </form>
  );
};

StudioForm.defaultProps = {
  studio: undefined,
  organizationId: undefined,
};

export default StudioForm;
