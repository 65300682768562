import { Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { IStudio } from '../../../../services/studios/studioTypes';

type Props = {
  studios?: IStudio[]
}

const StudiosList: FC<Props> = (props: Props) => {
  const orderedStudios = useMemo(
    () => props.studios?.sort((a, b) => (a.name?.toUpperCase() < b.name?.toUpperCase() ? -1 : 1)),
    [props.studios],
  );

  return (
    <div>
      {orderedStudios?.map((studio) => (
        <Typography key={`studio_list_${studio.id}`}>
          {studio.name}
        </Typography>
      ))}
    </div>
  );
};

StudiosList.defaultProps = {
  studios: undefined,
};

export default StudiosList;
