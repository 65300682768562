import React, { FC } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollToTop } from '../components/widgets/ScrollToTop';
import AddManagerPage from '../pages/AddManagerPage';
import AddStudioPage from '../pages/AddStudioPage';
import DailyTasksPage from '../pages/DailyTasksPage';
import DashboardPage from '../pages/DashboardPage';
import ManagersPage from '../pages/ManagersPage';
import SigninOidcPage from '../pages/SigninOidcPage';
import StudiosPage from '../pages/StudiosPage';
import TaskDetailPage from '../pages/TaskDetailPage';
import UpdateStudioPage from '../pages/UpdateStudioPage';
import PrivateRoute from './PrivateRoute';
import ManagerTaskssPage from '../pages/ManagerTasksPage';
import ManageTasksPage from '../pages/ManageTasksPage';
import AddTaskPage from '../pages/AddTaskPage';
import NotificationsPage from '../pages/NotificationsPage';
import ManageTaskPage from '../pages/ManageTaskPage';
import UpdateTaskPage from '../pages/UpdateTaskPage';
import OrganizationsPage from '../pages/OrganizationsPage';
import AddOrganizationPage from '../pages/AddOrganizationPage';
import UpdateOrganizationPage from '../pages/UpdateOrganizationPage';
import OrgAdminsPage from '../pages/OrgAdminsPage';
import AddOrgAdminPage from '../pages/AddOrgAdminPage';
import OrganizationStudiosPage from '../pages/OrganizationStudiosPage';

export const RoutePaths = {
  dashboard: '/',
  orgAdmins: (organizationId: string): string => `/org-admins/${organizationId}`,
  addOrgAdmin: (organizationId: string): string => `/add-org-admin/${organizationId}`,
  dailyTasks: '/daily-tasks',
  organizations: '/organizations',
  addOrganization: '/add-organization',
  updateOrganization: (organizationId: string): string => `/update-organization/${organizationId}`,
  studios: '/studios',
  organizationStudios: (organizationId: string): string => `/organization-studios/:${organizationId}`,
  addStudio: (organizationId: string): string => `/add-studio/:${organizationId}`,
  updateStudio: (studioId: string): string => `/update-studio/${studioId}`,
  managers: (studioId: string): string => `/managers/${studioId}`,
  taskDetail: (taskId: string): string => `/task-detail/${taskId}`,
  addManager: '/add-manager',
  managerTasks: (userId: string): string => `/manager-tasks/${userId}`,
  manageTasks: '/manage-tasks',
  manageTask: (taskScheduleId: string): string => `/manage-task/${taskScheduleId}`,
  updateTask: (taskScheduleId: string): string => `/update-task/${taskScheduleId}`,
  addTask: '/add-task',
  notifications: '/notifications',
};

const Routes: FC = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Switch>
      <Route path="/signin-oidc"><SigninOidcPage /></Route>
      <PrivateRoute exact path={RoutePaths.dashboard}><DashboardPage /></PrivateRoute>
      <PrivateRoute exact path={RoutePaths.dailyTasks}><DailyTasksPage /></PrivateRoute>
      <PrivateRoute exact path={RoutePaths.notifications}><NotificationsPage /></PrivateRoute>
      <PrivateRoute exact path={RoutePaths.taskDetail(':id')}><TaskDetailPage /></PrivateRoute>
      <PrivateRoute exact path={RoutePaths.organizations} admin><OrganizationsPage /></PrivateRoute>
      <PrivateRoute exact path={RoutePaths.addOrganization} admin>
        <AddOrganizationPage />
      </PrivateRoute>
      <PrivateRoute exact path={RoutePaths.updateOrganization(':organizationId')} admin>
        <UpdateOrganizationPage />
      </PrivateRoute>
      <PrivateRoute exact path={RoutePaths.addStudio(':organizationId')} admin>
        <AddStudioPage />
      </PrivateRoute>
      <PrivateRoute exact path={RoutePaths.studios} anyAdmin><StudiosPage /></PrivateRoute>
      <PrivateRoute exact path={RoutePaths.updateStudio(':studioId')} anyAdmin>
        <UpdateStudioPage />
      </PrivateRoute>
      <PrivateRoute exact path={RoutePaths.managers(':studioId')} anyAdmin><ManagersPage /></PrivateRoute>
      <PrivateRoute exact path={RoutePaths.addManager} anyAdmin><AddManagerPage /></PrivateRoute>
      <PrivateRoute exact path={RoutePaths.manageTasks} organizationAdmin>
        <ManageTasksPage />
      </PrivateRoute>
      <PrivateRoute exact path={RoutePaths.manageTask(':taskScheduleId')} organizationAdmin><ManageTaskPage /></PrivateRoute>
      <PrivateRoute exact path={RoutePaths.addTask} organizationAdmin><AddTaskPage /></PrivateRoute>
      <PrivateRoute exact path={RoutePaths.updateTask(':taskScheduleId')} organizationAdmin><UpdateTaskPage /></PrivateRoute>
      <PrivateRoute exact path={RoutePaths.managerTasks(':userId')} organizationAdmin><ManagerTaskssPage /></PrivateRoute>
      <PrivateRoute exact path={RoutePaths.orgAdmins(':organizationId')} admin><OrgAdminsPage /></PrivateRoute>
      <PrivateRoute exact path={RoutePaths.addOrgAdmin(':organizationId')} admin><AddOrgAdminPage /></PrivateRoute>
      <PrivateRoute exact path={RoutePaths.organizationStudios(':organizationId')} admin><OrganizationStudiosPage /></PrivateRoute>
    </Switch>
  </BrowserRouter>
);

export default Routes;
