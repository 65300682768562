import { Box, IconButton } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import React, { FC } from 'react';

export interface IProps {
  onPrevious: () => void
  onNext: () => void,
  hasPreviousPage: boolean,
  hasNextPage: boolean,
}

const LeftRightPagination: FC<IProps> = (props: IProps) => {
  if (!props.hasPreviousPage && !props.hasNextPage) return null;

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <IconButton
        aria-label="previous page"
        onClick={props.onPrevious}
        disabled={!props.hasPreviousPage}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        aria-label="next page"
        onClick={props.onNext}
        disabled={!props.hasNextPage}
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
};

export default LeftRightPagination;
