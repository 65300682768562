import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { ITask } from '../../../services/tasks/taskTypes';
import TaskListItem from './TaskListItem';
import { strings } from './TaskListView.strings';

export interface IProps {
  tasks?: ITask[]
}

const TaskListView: FC<IProps> = (props: IProps) => (
  <>
    {!props?.tasks?.length
      ? <Typography sx={{ mt: 2 }}>{strings.no_tasks}</Typography>
      : props?.tasks?.map((task) => (
        <TaskListItem key={task.id} task={task} />
      ))}
  </>
);

TaskListView.defaultProps = {
  tasks: undefined,
};

export default TaskListView;
