import { IUser, StudioRole } from './userTypes';

export const isAdmin = (user?: IUser): boolean => (
  user?.studioRole === StudioRole.ADMIN
);

export const isOrganizationAdmin = (user?: IUser): boolean => (
  user?.studioRole === StudioRole.ORGANIZATION_ADMIN
);

export const isAnyAdmin = (user?: IUser): boolean => (
  user?.studioRole === StudioRole.ORGANIZATION_ADMIN
    || user?.studioRole === StudioRole.ADMIN
);
