import { Route, RouteProps, useHistory } from 'react-router-dom';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { getUser, logIn } from '../services/auth/authService';
import { remember } from '../services/auth/loginRedirectService';
import { useMyProfileLazyQuery } from '../hooks/myProfiles/useMyProfileQuery';
import { RoutePaths } from './Routes';
import { StudioRole } from '../services/users/userTypes';
import { isAdmin, isAnyAdmin, isOrganizationAdmin } from '../services/users/userService';

export interface IPrivateRouteProps extends RouteProps {
  admin?: boolean
  organizationAdmin?: boolean
  anyAdmin?: boolean
}

const PrivateRoute: FunctionComponent<IPrivateRouteProps> = (props: IPrivateRouteProps) => {
  const [authenticated, setAuthenticated] = useState<boolean>();
  const {
    loadMyProfile,
    loading,
    myProfile,
    called,
  } = useMyProfileLazyQuery();
  const { children, ...routeProps } = props;
  const history = useHistory();

  useEffect(() => {
    getUser()
      .then((user) => {
        if (user) {
          setAuthenticated(true);
        } else {
          if (routeProps.location?.pathname) {
            remember(routeProps.location.pathname);
          }
          logIn();
        }
      });
  });

  useEffect(() => {
    if (authenticated && !loading && !called) {
      loadMyProfile();
    }
  }, [
    authenticated,
    loading,
    called,
    myProfile,
  ]);

  if (!authenticated || !myProfile) return null;

  if (props.admin && !isAdmin(myProfile)) return null;
  if (props.organizationAdmin && !isOrganizationAdmin(myProfile)) return null;
  if (props.anyAdmin && !isAnyAdmin(myProfile)) return null;

  if (authenticated && myProfile && routeProps.location?.pathname === '/') {
    if (myProfile.studioRole === StudioRole.ADMIN) {
      history.push(RoutePaths.organizations);
    } else if (myProfile.studioRole === StudioRole.ORGANIZATION_ADMIN) {
      history.push(RoutePaths.studios);
    } else {
      history.push(RoutePaths.dailyTasks);
    }
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Route {...routeProps}>
      {children}
    </Route>
  );
};

PrivateRoute.defaultProps = {
  admin: undefined,
  organizationAdmin: undefined,
  anyAdmin: undefined,
};

export default PrivateRoute;
