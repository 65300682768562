import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    SUNDAY: 'Sunday',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY_short: 'Sun',
    MONDAY_short: 'Mon',
    TUESDAY_short: 'Tue',
    WEDNESDAY_short: 'Wed',
    THURSDAY_short: 'Thu',
    FRIDAY_short: 'Fri',
    SATURDAY_short: 'Sat',
  },
});
