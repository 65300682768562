import { Container } from '@mui/material';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import Studios from '../components/studios/Studios';
import { useQueryString } from '../hooks/queryString/useQueryString';
import useQueryApi from '../hooks/useQueryApi';
import { GET_MY_PROFILE, IMeData } from '../services/users/userQueries';
import { isAdmin } from '../services/users/userService';
import strings from './Studios.strings';

const StudiosPage: FC = () => {
  const { data: myProfileData, loading } = useQueryApi<IMeData>(GET_MY_PROFILE);
  const queryString = useQueryString();

  if (loading) return null;
  const admin = isAdmin(myProfileData?.me);

  return (
    <Layout
      pageName={strings.studios}
      showBackButton={admin}
    >
      <Container>
        <Studios
          organizationId={admin
            ? queryString.get('organizationid') ?? undefined
            : undefined}
        />
      </Container>
    </Layout>
  );
};

export default StudiosPage;
