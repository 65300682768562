import { useContext } from 'react';
import { FeedbackContext, FeedbackSeverity } from '../../contexts/FeedbackProvider';
import { ADD_ORGANIZATION, UPDATE_ORGANIZATION } from '../../services/organizations/organizationMutations';
import { GET_ORGANIZATIONS } from '../../services/organizations/organizationQueries';
import { IOrganization, UpdateOrganizationInput } from '../../services/organizations/organizationTypes';
import useMutationApi from '../useMutationApi';

export interface IUseOrganizationMutation {
  addOrganization: (
    name: string,
  ) => Promise<void>,
  updateOrganization: (
    updateOrganizationInput: UpdateOrganizationInput,
  ) => Promise<void>,
}

export const useOrganizationMutations = (): IUseOrganizationMutation => {
  const { showFeedback } = useContext(FeedbackContext);
  const [addStudioMutation] = useMutationApi<
    { addOrganization: IOrganization },
    { name: string }
  >(ADD_ORGANIZATION, {
    refetchQueries: [
      { query: GET_ORGANIZATIONS },
    ],
    awaitRefetchQueries: true,
  });

  const [updateOrganizationMutation] = useMutationApi<
    { updateOrganization: IOrganization },
    { updateOrganizationInput: UpdateOrganizationInput }
  >(UPDATE_ORGANIZATION);

  const addOrganization = async (
    name: string,
  ): Promise<void> => {
    await addStudioMutation({
      variables: { name },
    });
    showFeedback('Added', FeedbackSeverity.success);
  };

  const updateOrganization = async (
    updateOrganizationInput: UpdateOrganizationInput,
  ): Promise<void> => {
    await updateOrganizationMutation({
      variables: { updateOrganizationInput },
    });
    showFeedback('Saved', FeedbackSeverity.success);
  };

  return {
    addOrganization,
    updateOrganization,
  };
};
