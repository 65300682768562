type Config = {
  appUrl: string,
  api: {
    route: string,
  },
  auth: {
    domain: string,
  },
  awsConfigAuth: {
    region: string,
    userPoolId: string,
    userPoolWebClientId: string,
    oauth: {
      domain: string,
      scope: string[],
      redirectSignIn: string,
      redirectSignOut: string,
      responseType: string,
    }
  }
};

const createConfig = (): Config => {
  const currentUrl = window.location.href?.toLowerCase();

  if (currentUrl.startsWith('https://taskmanager-f45.org')) {
    return {
      appUrl: 'https://taskmanager-f45.org',
      api: {
        route: 'https://api.taskmanager-f45.org/graphql',
      },
      auth: {
        domain: 'hc-prod',
      },
      awsConfigAuth: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_zuYWxMNcl',
        userPoolWebClientId: '2qmsnh1qvu4sraijkv49oa5fo6',
        oauth: {
          domain: 'auth.taskmanager-f45.org',
          scope: ['email', 'profile', 'openid'],
          redirectSignIn: 'https://taskmanager-f45.org/',
          redirectSignOut: 'https://taskmanager-f45.org/',
          responseType: 'code',
        },
      },
    };
  }

  return {
    appUrl: 'http://localhost:5678',
    api: {
      route: 'https://localhost:4001/graphql',
    },
    auth: {
      domain: 'hc-dev',
    },
    awsConfigAuth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_kN9xIlQUm',
      userPoolWebClientId: '3upus0fhjad9a969jv0lh17vki',
      oauth: {
        domain: 'hc-dev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: 'http://localhost:5678/',
        redirectSignOut: 'http://localhost:5678/',
        responseType: 'code',
      },
    },
  };
};

export default createConfig();
