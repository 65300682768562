import {
  Button, Checkbox, FormControl, FormControlLabel, Grid,
  InputLabel, ListItemText, MenuItem, Paper, Radio, RadioGroup, Select, TextField,
} from '@mui/material';
import { Done } from '@mui/icons-material';
import React, { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import update from 'immutability-helper';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import CancelButton from '../../navigation/CancelButton';
import { strings } from './TaskForm.strings';
import {
  DayOfWeek, dayOfWeekDisplay, daysOfWeek, displayTimeToDateTime,
} from '../../../services/utils/dateTimeService';
import { StudioRole } from '../../../services/users/userTypes';
import { ITaskScheduleDetail, TaskScheduleType, UserTaskScheduleInput } from '../../../services/taskSchedules/taskScheduleTypes';
import { useTaskScheduleMutation } from '../../../hooks/taskSchedules/useTaskScheduleMutation';
import { RoutePaths } from '../../../routing/Routes';
import { useStudiosQuery } from '../../../hooks/studios/useStudiosQuery';
import ClickDatePicker from '../../dateTime/ClickDatePicker';
import ButtonsContainer from '../../navigation/ButtonsContainer';
import ClickTimePicker from '../../dateTime/ClickTimePicker';

type Inputs = {
  title: string,
  description: string,
  studios: string[],
  roles: StudioRole[],
  daysOfWeek: DayOfWeek[],
  dayOfMonth: number,
  oneOffDate: DateTime,
  scheduleType: TaskScheduleType,
  timeFrom: DateTime,
  timeTo: DateTime,
}

type Props = {
  taskScheduleDetails?: ITaskScheduleDetail,
}

const TaskForm: FC<Props> = (props: Props) => {
  const { push } = useHistory();
  const [
    taskScheduleType,
    setTaskScheduleType,
  ] = useState<TaskScheduleType>(TaskScheduleType.WEEKLY);
  const {
    handleSubmit, control, formState,
  } = useForm<Inputs>();
  const { loading, studios } = useStudiosQuery();
  const { addTaskSchedule, updateTaskSchedule } = useTaskScheduleMutation();

  const onSubmit = async (inputs: Inputs) => {
    const taskScheduleInput: UserTaskScheduleInput = {
      ...inputs,
      timeFrom: inputs.timeFrom?.toLocaleString(DateTime.TIME_24_SIMPLE),
      timeTo: inputs.timeTo?.toLocaleString(DateTime.TIME_24_SIMPLE),
      oneOffDate: inputs.oneOffDate?.toJSDate(),
    };

    if (props.taskScheduleDetails) {
      await updateTaskSchedule(
        props.taskScheduleDetails.id,
        taskScheduleInput,
      );
    } else {
      await addTaskSchedule(taskScheduleInput);
    }

    push(RoutePaths.manageTasks);
  };

  useEffect(() => {
    if (!props.taskScheduleDetails) return;
    setTaskScheduleType(props.taskScheduleDetails.scheduleType);
  }, [props.taskScheduleDetails]);

  if (loading) return null;

  const isEdit = !!props.taskScheduleDetails;

  return (
    <Paper
      sx={{
        mt: 2,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '800px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit as SubmitHandler<Inputs>)}>
        <Grid container spacing={4} sx={{ mb: 6 }}>

          <Grid item xs={6} md={3}>
            <Controller
              name="timeFrom"
              control={control}
              defaultValue={(
                displayTimeToDateTime(props.taskScheduleDetails?.timeFrom) ?? DateTime.now()
              )}
              render={({ field }) => (
                <ClickTimePicker
                  {...field}
                  label={strings.time_from}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Controller
              name="timeTo"
              control={control}
              defaultValue={(
                displayTimeToDateTime(props.taskScheduleDetails?.timeTo) ?? DateTime.now()
              )}
              render={({ field }) => (
                <ClickTimePicker
                  {...field}
                  label={strings.time_to}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="title"
              control={control}
              defaultValue={(props.taskScheduleDetails?.title ?? '')}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={strings.title}
                  variant="outlined"
                  required
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              defaultValue={(props.taskScheduleDetails?.description ?? '')}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={strings.description}
                  variant="outlined"
                  multiline
                  minRows={3}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="studios"
              control={control}
              defaultValue={(
                props.taskScheduleDetails?.userTaskScheduleStudios.map((s) => s.studio.id) ?? []
              )}
              render={({ field }) => (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="add-task-studio-label">{strings.studio}</InputLabel>
                  <Select
                    {...field}
                    required
                    labelId="add-task-studio-label"
                    label={strings.studio}
                    value={field.value}
                    onChange={(event, child) => {
                      const value = event.target?.value as string[];
                      if (value[value.length - 1] === 'ALL') {
                        field.onChange(
                          update(
                            event,
                            {
                              target: {
                                value: {
                                  $set: (field.value.length ? [] : studios?.map((s) => s.id)) ?? [],
                                },
                              },
                            },
                          ),
                        );
                        return;
                      }
                      field.onChange(event, child);
                    }}
                    renderValue={(selected) => (selected as string[]).map((s) => studios?.find((st) => st.id === s)?.name).join(', ')}
                    multiple
                  >
                    <MenuItem value="ALL">
                      <ListItemText primary={strings.all} />
                    </MenuItem>
                    {studios?.map((studio) => (
                      <MenuItem key={studio.id} value={studio.id}>
                        <Checkbox checked={field.value.includes(studio.id)} />
                        <ListItemText primary={studio.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="roles"
              control={control}
              defaultValue={(props.taskScheduleDetails?.roles ?? [])}
              render={({ field }) => (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="add-task-role-label">{strings.role}</InputLabel>
                  <Select
                    {...field}
                    required
                    labelId="add-task-role-label"
                    label={strings.role}
                    value={field.value}
                    renderValue={(selected) => (selected as string[]).map((s) => (strings as unknown as Record<string, unknown>)[s]).join(', ')}
                    multiple
                  >
                    <MenuItem value={StudioRole.STUDIO_MANAGER}>
                      <Checkbox checked={field.value.includes(StudioRole.STUDIO_MANAGER)} />
                      <ListItemText primary={strings[StudioRole.STUDIO_MANAGER]} />
                    </MenuItem>
                    <MenuItem value={StudioRole.FRONT_DESK}>
                      <Checkbox checked={field.value.includes(StudioRole.FRONT_DESK)} />
                      <ListItemText primary={strings[StudioRole.FRONT_DESK]} />
                    </MenuItem>
                    <MenuItem value={StudioRole.FITNESS}>
                      <Checkbox checked={field.value.includes(StudioRole.FITNESS)} />
                      <ListItemText primary={strings[StudioRole.FITNESS]} />
                    </MenuItem>
                    <MenuItem value={StudioRole.SALES}>
                      <Checkbox checked={field.value.includes(StudioRole.SALES)} />
                      <ListItemText primary={strings[StudioRole.SALES]} />
                    </MenuItem>
                    <MenuItem value={StudioRole.REGIONAL_MANAGER}>
                      <Checkbox checked={field.value.includes(StudioRole.REGIONAL_MANAGER)} />
                      <ListItemText primary={strings[StudioRole.REGIONAL_MANAGER]} />
                    </MenuItem>
                    <MenuItem value={StudioRole.VIRTUAL_ASSISTANT}>
                      <Checkbox checked={field.value.includes(StudioRole.VIRTUAL_ASSISTANT)} />
                      <ListItemText primary={strings[StudioRole.VIRTUAL_ASSISTANT]} />
                    </MenuItem>
                    <MenuItem value={StudioRole.REMOTE_SALES}>
                      <Checkbox checked={field.value.includes(StudioRole.REMOTE_SALES)} />
                      <ListItemText primary={strings[StudioRole.REMOTE_SALES]} />
                    </MenuItem>
                    <MenuItem value={StudioRole.EXECUTIVE_ASSISTANT}>
                      <Checkbox checked={field.value.includes(StudioRole.EXECUTIVE_ASSISTANT)} />
                      <ListItemText primary={strings[StudioRole.EXECUTIVE_ASSISTANT]} />
                    </MenuItem>
                    <MenuItem value={StudioRole.ASSISTANT_MANAGER}>
                      <Checkbox checked={field.value.includes(StudioRole.ASSISTANT_MANAGER)} />
                      <ListItemText primary={strings[StudioRole.ASSISTANT_MANAGER]} />
                    </MenuItem>
                    <MenuItem value={StudioRole.FITNESS_CONSULTANT}>
                      <Checkbox checked={field.value.includes(StudioRole.FITNESS_CONSULTANT)} />
                      <ListItemText primary={strings[StudioRole.FITNESS_CONSULTANT]} />
                    </MenuItem>
                    <MenuItem value={StudioRole.DISTRICT_MANAGER}>
                      <Checkbox checked={field.value.includes(StudioRole.DISTRICT_MANAGER)} />
                      <ListItemText primary={strings[StudioRole.DISTRICT_MANAGER]} />
                    </MenuItem>
                    <MenuItem value={StudioRole.SENIOR_TRAINER}>
                      <Checkbox checked={field.value.includes(StudioRole.SENIOR_TRAINER)} />
                      <ListItemText primary={strings[StudioRole.SENIOR_TRAINER]} />
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="scheduleType"
              control={control}
              defaultValue={(props.taskScheduleDetails?.scheduleType ?? TaskScheduleType.WEEKLY)}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  row
                  defaultValue={taskScheduleType || TaskScheduleType.WEEKLY}
                  onChange={(e) => {
                    setTaskScheduleType(
                      e?.target?.value as TaskScheduleType || TaskScheduleType.WEEKLY,
                    );
                    field.onChange(e);
                  }}
                >
                  <FormControlLabel value={TaskScheduleType.WEEKLY} control={<Radio color="primary" />} label={strings.days_of_week} />
                  <FormControlLabel value={TaskScheduleType.MONTHLY} control={<Radio color="primary" />} label={strings.day_of_month} />
                  <FormControlLabel value={TaskScheduleType.ONEOFF} control={<Radio color="primary" />} label={strings.one_off} />
                </RadioGroup>
              )}
            />
          </Grid>

          {taskScheduleType === TaskScheduleType.WEEKLY && (
            <Grid item xs={12}>
              <Controller
                name="daysOfWeek"
                control={control}
                defaultValue={(props.taskScheduleDetails?.daysOfWeek ?? [])}
                render={({ field }) => (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="add-task-daysofweek-label">{strings.days_of_week}</InputLabel>
                    <Select
                      {...field}
                      required
                      labelId="add-task-daysofweek-label"
                      label={strings.days_of_week}
                      value={field.value}
                      renderValue={(selected) => (selected as DayOfWeek[]).map((s) => dayOfWeekDisplay(s)).join(', ')}
                      multiple
                    >
                      {daysOfWeek.map((dayOfWeek) => (
                        <MenuItem value={dayOfWeek} key={`day_of_week_${dayOfWeek}`}>
                          <Checkbox checked={field.value.includes(dayOfWeek)} />
                          <ListItemText primary={dayOfWeekDisplay(dayOfWeek)} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          )}

          {taskScheduleType === TaskScheduleType.MONTHLY && (
            <Grid item xs={3}>
              <Controller
                name="dayOfMonth"
                control={control}
                defaultValue={(props.taskScheduleDetails?.dayOfMonth ?? 1)}
                render={({ field }) => (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="add-task-dayofmonth-label">{strings.day_of_month}</InputLabel>
                    <Select
                      {...field}
                      required
                      labelId="add-task-dayofmonth-label"
                      label={strings.day_of_month}
                      value={field.value}
                    >
                      {Array.from(Array(31).keys()).map((k) => (
                        <MenuItem value={k + 1} key={`dayofmonth_${k}`}>
                          {k + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          )}

          {taskScheduleType === TaskScheduleType.ONEOFF && (
            <Grid item xs={12}>
              <Controller
                name="oneOffDate"
                control={control}
                defaultValue={(
                  props.taskScheduleDetails?.oneOffDate
                    ? DateTime.fromISO(props.taskScheduleDetails?.oneOffDate)
                    : DateTime.now()
                )}
                render={({ field }) => (
                  <ClickDatePicker
                    {...field}
                    label={strings.schedule_date}
                  />
                )}
              />
            </Grid>
          )}

        </Grid>

        <ButtonsContainer>
          <CancelButton />
          <Button
            type="submit"
            color="secondary"
            startIcon={<Done />}
            variant="contained"
            disabled={formState.isSubmitting}
          >
            {(isEdit ? strings.update_task : strings.schedule_task)}
          </Button>
        </ButtonsContainer>
      </form>
    </Paper>
  );
};

TaskForm.defaultProps = {
  taskScheduleDetails: undefined,
};

export default TaskForm;
