import { gql } from '@apollo/client';
import { IConnection } from '../queries/queryTypes';
import { ITaskSchedule, ITaskScheduleDetail } from './taskScheduleTypes';

export const TASK_SCHEDULES_TAKE = 10;

export interface ITaskSchedulessData {
  userTaskSchedules: IConnection<ITaskSchedule>,
}

export interface ITaskScheduleDetailData {
  userTaskSchedule: ITaskScheduleDetail,
}

export const TASK_SCHEDULE_DETAIL_FIELDS = gql`
  fragment taskScheduleDetailFields on UserTaskSchedule {
    title
    description
    userTaskScheduleStudios {
      studio {
        name
        id
      }
    }
    roles
    daysOfWeek
    dayOfMonth
    oneOffDate
    scheduleType
    timeFrom
    timeTo
    createdById
    deleted
    deletedBy
    id
    createdOn
  }
`;

export const TASK_SCHEDULE_FIELDS = gql`
  fragment taskScheduleFields on UserTaskSchedule {
    title
    description
    timeFrom
    timeTo
    id
    createdOn
    scheduleType
    daysOfWeek
    dayOfMonth
    oneOffDate
  }
`;

export const GET_TASK_SCHEDULES = gql`
  query ($where: UserTaskScheduleFilterInput, $before: String, $after: String, $first: Int, $last: Int) {
    userTaskSchedules (where: $where, before: $before, after: $after, first: $first, last: $last, order: { createdOn: DESC }) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        ...taskScheduleFields
      }
    }
  }
  ${TASK_SCHEDULE_FIELDS}
`;

export const GET_TASK_SCHEDULE = gql`
  query ($id: UUID!) {
    userTaskSchedule (id: $id) {
      ...taskScheduleDetailFields
    }
  }
  ${TASK_SCHEDULE_DETAIL_FIELDS}
`;
