import { Container } from '@mui/material';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import AddAdminForm from '../components/admins/AddAdminForm';
import Layout from '../components/Layout';
import strings from './AddOrgAdminPage.strings';

const AdOrgAdminPage: FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  return (
    <Layout pageName={strings.add_manager} showBackButton>
      <Container>
        <AddAdminForm organizationId={organizationId} />
      </Container>
    </Layout>
  );
};

export default AdOrgAdminPage;
