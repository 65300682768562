import { Container } from '@mui/material';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import strings from './DashboardPage.strings';

const DashboardPage: FC = () => (
  <Layout pageName={strings.dashboard}>
    <Container>
      Welcome!
    </Container>
  </Layout>
);

export default DashboardPage;
