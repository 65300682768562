import React, { FC, PropsWithChildren } from 'react';
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles';

const MainTheme: FC = (props: PropsWithChildren<unknown>) => {
  let theme = createTheme({
    palette: {
      // type: "dark"
      primary: {
        main: '#211551',
        light: '#4e3c7e',
        dark: '#000029',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#d6001c',
        light: '#ff5146',
        dark: '#9c0000',
        contrastText: '#ffffff',
      },
    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  );
};

export default MainTheme;
