import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    name: 'Name',
    email: 'Email address',
    username: 'Username',
    save: 'Save',
    role: 'Studio role',
    head_trainer: 'Head Trainer',
    sales_mamager: 'Sales and Member Manager',
    front_desk: 'Front Desk',
    studio_manager: 'Studio Manager',
    studio: 'Studio',
    regional_manager: 'Regional Manager',
    virtual_assistant: 'Virtual Assistant',
    remote_sales: 'Remote Sales',
    executive_assistant: 'Executive Assistant',
    assistant_manager: 'Assistant Manager',
    fitenss_consultant: 'Fitness Consultant',
    district_manager: 'District Manager',
    senior_trainer: 'Senior Trainer',
  },
});
