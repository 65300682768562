import { GET_MY_PROFILE, IMeData } from '../../services/users/userQueries';
import { IUser } from '../../services/users/userTypes';
import useLazyQueryApi from '../useLazyQueryApi';

export interface IMyProfileQuery {
  loadMyProfile: () => void,
  myProfile?: IUser,
  loading: boolean,
  called: boolean,
}

export const useMyProfileLazyQuery = (): IMyProfileQuery => {
  const [
    loadMyProfile,
    { loading, data, called },
  ] = useLazyQueryApi<IMeData>(GET_MY_PROFILE);

  return {
    loadMyProfile,
    myProfile: data?.me,
    loading,
    called,
  };
};
