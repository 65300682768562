import { GET_TASK_SCHEDULE, ITaskScheduleDetailData } from '../../services/taskSchedules/taskScheduleQueries';
import { ITaskScheduleDetail } from '../../services/taskSchedules/taskScheduleTypes';
import useQueryApi from '../useQueryApi';

export interface ITaskScheduleDetailsQuery {
  taskScheduleDetails?: ITaskScheduleDetail,
  loading: boolean,
  called: boolean,
}

export const useTaskScheduleDetailsQuery = (id: string): ITaskScheduleDetailsQuery => {
  const { data, loading, called } = useQueryApi<ITaskScheduleDetailData>(GET_TASK_SCHEDULE, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  return {
    taskScheduleDetails: data?.userTaskSchedule,
    loading,
    called,
  };
};
