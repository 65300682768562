import { gql } from '@apollo/client';
import { STUDIO_FIELDS } from './studioQueries';

export const ADD_STUDIO = gql`
  mutation addStudio($name: String!, $organizationId: UUID!) {
    addStudio(name: $name, organizationId: $organizationId) {
      ...studioFields
    }
  }
  ${STUDIO_FIELDS}
`;

export const UPDATE_STUDIO = gql`
  mutation addStudio($updateStudioInput: UpdateStudioInput!) {
    updateStudio(updateStudioInput: $updateStudioInput) {
      ...studioFields
    }
  }
  ${STUDIO_FIELDS}
`;
