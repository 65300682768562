import { Container } from '@mui/material';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import Organizations from '../components/organizations/Organizations';
import strings from './OrganizationsPage.strings';

const StudiosPage: FC = () => (
  <Layout pageName={strings.organizations}>
    <Container>
      <Organizations />
    </Container>
  </Layout>
);

export default StudiosPage;
