import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    days_of_week: 'Days Of Week',
    day_of_month: 'Day Of Month',
    one_off: 'One Off',
    schedule_task: 'Schedule Task',
  },
});
