import { gql } from '@apollo/client';
import { IOrganization } from './organizationTypes';

export interface IOrganizationsData {
  organizations: IOrganization[],
}

export const ORGANIZATION_FIELDS = gql`
  fragment organizationFields on Organization {
      id
      name
    }
`;

export const GET_ORGANIZATIONS = gql`
  query {
    organizations (order: { name: ASC }) {
      ...organizationFields
    }
  }
  ${ORGANIZATION_FIELDS}
`;

export interface IOrganizationData {
  organization: IOrganization,
}

export const GET_ORGANIZATION = gql`
  query ($id: UUID!) {
    organization (id: $id) {
      ...organizationFields
    }
  }
  ${ORGANIZATION_FIELDS}
`;
