import { Button, Menu, MenuItem } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useMyProfileLazyQuery } from '../../hooks/myProfiles/useMyProfileQuery';
import { logOut } from '../../services/auth/authService';
import strings from './UserButton.strings';

const UserButton: FC = () => {
  const { myProfile, loadMyProfile } = useMyProfileLazyQuery();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => { loadMyProfile(); }, []);

  if (!myProfile) return null;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    handleClose();
    logOut();
  };

  return (
    <>
      <Button color="inherit" onClick={handleClick}>
        {myProfile.name}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogOut}>{strings.logOut}</MenuItem>
      </Menu>
    </>
  );
};

export default UserButton;
