export enum TaskStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export interface ITask {
  id: string,
  startTime: string,
  endTime: string,
  date: string,
  title: string,
  status: TaskStatus,
  assignedToId: string,
}

export interface ITaskDetail extends ITask {
  description?: string,
  comment?: string,
  completedOn?: string,
}

export interface ITaskValuesInput {
  id: string,
  comment?: string,
}
