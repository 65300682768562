/* eslint-disable react/jsx-props-no-spreading */
import { Box } from '@mui/material';
import React, { FC } from 'react';
import { withRouter } from 'react-router-dom';
import NavigationBar, { Props as NavigationBarProps } from './navigation/NavigationBar';
import ProgressIndicator from './progressIndicators/ProgressIndicator';

type Props = NavigationBarProps & {
  hiddenNavigation?: boolean,
  children: React.ReactNode,
};

export const Layout: FC<Props> = (props: Props) => {
  const { children, ...otherProps } = props;

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: 'grey.50',
        minHeight: '100vh',
        [theme.breakpoints.up('lg')]: {
          display: 'flex',
        },
      })}
    >
      <ProgressIndicator />
      {!props.hiddenNavigation
        && <NavigationBar {...otherProps} />}
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: 'grey.50',
          paddingBottom: 2,
        }}
      >
        {!props.hiddenNavigation
        && <Box sx={(theme) => theme.mixins.toolbar} />}
        {children}
      </Box>
    </Box>
  );
};

Layout.defaultProps = {
  hiddenNavigation: false,
};

export default withRouter(Layout);
