import {
  useMutation, DocumentNode, MutationHookOptions, MutationTuple, OperationVariables,
} from '@apollo/client';
import { useContext, useEffect } from 'react';
import { FeedbackContext, FeedbackSeverity } from '../contexts/FeedbackProvider';
import useProgressIndicator from './useProgressIndicator';

export default function useMutationApi<
  TData = unknown,
  TVariables = OperationVariables
>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> {
  const mutationResult = useMutation<TData, TVariables>(mutation, options);
  const { showFeedback } = useContext(FeedbackContext);
  const { progressStarted, progressEnded } = useProgressIndicator();

  const { error: mutationError } = mutationResult[1];

  useEffect(() => {
    if (mutationError) {
      showFeedback(mutationError.message, FeedbackSeverity.error);
    }
  }, [
    mutationError,
  ]);

  useEffect(() => {
    if (mutationResult[1]?.loading) {
      progressStarted();
    } else {
      progressEnded();
    }
  }, [
    mutationResult[1]?.loading,
  ]);

  return mutationResult;
}
