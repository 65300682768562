import { Container } from '@mui/material';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import AddOrganization from '../components/organizations/AddOrganization';
import strings from './AddOrganizationPage.strings';

const AddStudioPage: FC = () => (
  <Layout pageName={strings.add_organization} showBackButton>
    <Container>
      <AddOrganization />
    </Container>
  </Layout>
);

export default AddStudioPage;
