/* eslint-disable no-underscore-dangle */
import React, { FC, PropsWithChildren } from 'react';
import {
  ApolloProvider, ApolloClient, InMemoryCache, HttpLink, from, split,
} from '@apollo/client';
import { onError } from '@apollo/link-error';
// import { WebSocketLink } from '@apollo/link-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import config from '../../config';
import { reLogin } from '../../services/auth/authService';
import { authLink } from './authMiddleware';

const httpLink = new HttpLink({
  uri: config.api.route,
});

// const wsLink = new WebSocketLink({
//   uri: config.api.wsRoute,
//   options: {
//     reconnect: true,
//   },
// });

const errorLink = onError(({ networkError, response }) => {
  const is401 = networkError
    && 'statusCode' in networkError
    && networkError.statusCode === 401;

  const isUnauthorized = !!response?.errors?.length
    && !!response.errors.find((error) => error.extensions && error.extensions.code === 'AUTH_NOT_AUTHORIZED');

  if (is401 || isUnauthorized) {
    reLogin();
    if (response) response.errors = undefined;
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition'
        && definition.operation === 'subscription'
    );
  },
  // wsLink,
  httpLink,
);

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      PlanningAnswer: {
        keyFields: ['planningQuestionId'],
      },
      Profile: {
        keyFields: ['userId'],
      },
    },
  }),
  link: from([authLink, errorLink, splitLink, httpLink]),
});

const AppQuery: FC = (props: PropsWithChildren<unknown>) => (
  <ApolloProvider client={client}>
    {props.children}
  </ApolloProvider>
);

export default AppQuery;
