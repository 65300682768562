import {
  Box, Button, Paper, Typography,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import React, { FC, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { LinkItUrl } from 'react-linkify-it';
import { useTaskScheduleDetailsQuery } from '../../../../hooks/taskSchedules/useTaskScheduleDetailsQuery';
import { RoutePaths } from '../../../../routing/Routes';
import { TaskScheduleType } from '../../../../services/taskSchedules/taskScheduleTypes';
import { StudioRole } from '../../../../services/users/userTypes';
import { dayOfWeekDisplay, printDateWithoutZone, printTime } from '../../../../services/utils/dateTimeService';
import CancelButton from '../../../navigation/CancelButton';
import { strings } from './ManageTask.strings';
import StudiosList from './StudiosList';
import ButtonsContainer from '../../../navigation/ButtonsContainer';

const SectionDiv = styled('div')({
  marginTop: 2,
});

const ManageTask: FC = () => {
  const { push } = useHistory();
  const { taskScheduleId } = useParams<{ taskScheduleId: string }>();
  const { taskScheduleDetails, loading } = useTaskScheduleDetailsQuery(taskScheduleId);

  const studios = useMemo(
    () => taskScheduleDetails?.userTaskScheduleStudios.map((s) => s.studio),
    [taskScheduleDetails?.userTaskScheduleStudios],
  );

  if (loading || !taskScheduleDetails) return null;

  return (
    <Paper
      sx={{
        marginTop: 2,
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '800px',
        marginLeft: 'auto',
        marginRight: 'auto',
        '& > h5': {
          marginBottom: 2,
        },
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {`${printTime(taskScheduleDetails.timeFrom)} - ${printTime(taskScheduleDetails.timeTo)}`}
      </Typography>

      <Typography variant="h5">
        {taskScheduleDetails.title}
      </Typography>

      <Box sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
        <Typography>
          <LinkItUrl>{taskScheduleDetails.description}</LinkItUrl>
        </Typography>
      </Box>

      <SectionDiv>
        <Typography color="textSecondary">
          {strings.studio}
        </Typography>
        <StudiosList studios={studios} />
      </SectionDiv>

      <SectionDiv>
        <Typography color="textSecondary">
          {strings.role}
        </Typography>
        {taskScheduleDetails.roles?.map((r) => (
          <Typography key={r}>
            {strings[
              r as StudioRole.FITNESS
              | StudioRole.FRONT_DESK
              | StudioRole.SALES
              | StudioRole.STUDIO_MANAGER
              | StudioRole.REGIONAL_MANAGER
              | StudioRole.VIRTUAL_ASSISTANT
              | StudioRole.REMOTE_SALES
              | StudioRole.EXECUTIVE_ASSISTANT
              | StudioRole.ASSISTANT_MANAGER
              | StudioRole.FITNESS_CONSULTANT
              | StudioRole.DISTRICT_MANAGER
              | StudioRole.SENIOR_TRAINER
            ]}
          </Typography>
        ))}
      </SectionDiv>

      {taskScheduleDetails.scheduleType === TaskScheduleType.ONEOFF && (
        <SectionDiv>
          <Typography color="textSecondary">
            {strings.one_off}
          </Typography>
          <Typography>
            {printDateWithoutZone(taskScheduleDetails.oneOffDate)}
          </Typography>
        </SectionDiv>
      )}

      {taskScheduleDetails.scheduleType === TaskScheduleType.MONTHLY && (
        <SectionDiv>
          <Typography color="textSecondary">
            {strings.day_of_month}
          </Typography>
          <Typography>
            {taskScheduleDetails.dayOfMonth}
          </Typography>
        </SectionDiv>
      )}

      {taskScheduleDetails.scheduleType === TaskScheduleType.WEEKLY && (
        <SectionDiv>
          <Typography color="textSecondary">
            {strings.days_of_week}
          </Typography>
          {taskScheduleDetails.daysOfWeek.map((d) => (
            <Typography key={d}>
              {dayOfWeekDisplay(d)}
            </Typography>
          ))}
        </SectionDiv>
      )}

      <ButtonsContainer>
        <Button
          type="submit"
          color="secondary"
          startIcon={<Edit />}
          variant="outlined"
          onClick={() => push(RoutePaths.updateTask(taskScheduleId))}
        >
          {strings.edit}
        </Button>
        <CancelButton />
      </ButtonsContainer>
    </Paper>
  );
};

export default ManageTask;
