import { useContext } from 'react';
import { FeedbackContext, FeedbackSeverity } from '../../contexts/FeedbackProvider';
import { ADD_STUDIO, UPDATE_STUDIO } from '../../services/studios/studioMutations';
import { IStudio, UpdateStudioInput } from '../../services/studios/studioTypes';
import useMutationApi from '../useMutationApi';

export interface IUseStudioMutation {
  addStudio: (
    name: string,
    organizationid: string,
  ) => Promise<void>,
  updateStudio: (
    updateStudioInput: UpdateStudioInput,
  ) => Promise<void>,
}

export const useStudioMutation = (): IUseStudioMutation => {
  const { showFeedback } = useContext(FeedbackContext);
  const [addStudioMutation] = useMutationApi<
    { addStudio: IStudio },
    { name: string, organizationId: string }
  >(ADD_STUDIO);

  const [updateStudioMutation] = useMutationApi<
    { updateStudio: IStudio },
    { updateStudioInput: UpdateStudioInput }
  >(UPDATE_STUDIO);

  const addStudio = async (
    name: string,
    organizationId: string,
  ): Promise<void> => {
    await addStudioMutation({
      variables: { name, organizationId },
    });
    showFeedback('Added', FeedbackSeverity.success);
  };

  const updateStudio = async (
    updateStudioInput: UpdateStudioInput,
  ): Promise<void> => {
    await updateStudioMutation({
      variables: { updateStudioInput },
    });
    showFeedback('Saved', FeedbackSeverity.success);
  };

  return {
    addStudio,
    updateStudio,
  };
};
