import { useContext } from 'react';
import { FeedbackContext, FeedbackSeverity } from '../../contexts/FeedbackProvider';
import { COMPLETE_TASK, SAVE_TASK_VALUES } from '../../services/tasks/taskMurtations';
import { ITaskDetail, ITaskValuesInput } from '../../services/tasks/taskTypes';
import useMutationApi from '../useMutationApi';

export interface IUseTaskMutation {
  saveTaskValues: (
    userTaskValues: ITaskValuesInput,
  ) => Promise<void>,
  completeTask: (
    userTaskValues: ITaskValuesInput,
  ) => Promise<void>,
}

export const useTaskMutation = (): IUseTaskMutation => {
  const { showFeedback } = useContext(FeedbackContext);
  const [saveUserTaskMutation] = useMutationApi<
    { saveUserTask: ITaskDetail },
    { userTaskValues: ITaskValuesInput }
  >(SAVE_TASK_VALUES);

  const [completeUserTaskMutation] = useMutationApi<
    { completeUserTask: ITaskDetail },
    { userTaskValues: ITaskValuesInput }
  >(COMPLETE_TASK);

  const saveTaskValues = async (
    taskValuesInput: ITaskValuesInput,
  ): Promise<void> => {
    await saveUserTaskMutation({
      variables: { userTaskValues: taskValuesInput },
    });
    showFeedback('Saved', FeedbackSeverity.success);
  };

  const completeTask = async (
    taskValuesInput: ITaskValuesInput,
  ): Promise<void> => {
    await completeUserTaskMutation({
      variables: { userTaskValues: taskValuesInput },
    });
    showFeedback('Completed', FeedbackSeverity.success);
  };

  return {
    saveTaskValues,
    completeTask,
  };
};
