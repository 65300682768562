import React, {
  createContext, FC, useMemo, useState, PropsWithChildren,
} from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertColor } from '@mui/lab/Alert';

export enum FeedbackSeverity {
    success = 'success',
    error = 'error',
    warning = 'warning',
    info = 'info'
}

export interface IFeedbackState {
    showFeedback: (message: string, severity: FeedbackSeverity) => void
}

interface ISnackbarState {
    message?: string,
    open: boolean,
    severity?: FeedbackSeverity
}

export const FeedbackContext = createContext<IFeedbackState>({
  showFeedback: () => {
    // default
  },
});

export const FeedbackProvider: FC = (props: PropsWithChildren<unknown>) => {
  const [snackbarState, setSnackbarState] = useState<ISnackbarState>({ open: false });

  const providerState = useMemo(() => ({
    showFeedback: (message: string, severity: FeedbackSeverity) => {
      setSnackbarState({
        open: true,
        message,
        severity,
      });
    },
  }), [setSnackbarState]);

  const snackbarClose = () => setSnackbarState({ open: false });

  return (
    <FeedbackContext.Provider value={providerState}>
      {props.children}
      {snackbarState.open
            && (
            <Snackbar
              open={snackbarState.open}
              autoHideDuration={6000}
              onClose={snackbarClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <MuiAlert
                onClose={snackbarClose}
                severity={snackbarState.severity as AlertColor}
                variant="filled"
                elevation={5}
              >
                {snackbarState.message}
              </MuiAlert>
            </Snackbar>
            )}
    </FeedbackContext.Provider>
  );
};
