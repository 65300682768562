import { useContext, useState, useEffect } from 'react';
import { ProgressIndicatorContext } from '../contexts/ProgressIndicatorProvider';
import guid from '../services/guid';

export interface IUseProgressIndicator {
  progressStarted: () => void,
  progressEnded: () => void
}

export default function useProgressIndicator(): IUseProgressIndicator {
  const dispatchProgress = useContext(ProgressIndicatorContext);
  const [key] = useState<string>(guid());

  useEffect(() => () => {
    if (dispatchProgress) dispatchProgress({ type: 'progressEnded', key });
  }, []);

  const start = () => {
    if (dispatchProgress) dispatchProgress({ type: 'progressStarted', key });
  };

  const end = () => {
    if (dispatchProgress) dispatchProgress({ type: 'progressEnded', key });
  };

  return {
    progressStarted: start,
    progressEnded: end,
  };
}
