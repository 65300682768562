import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAccessToken } from '../../services/auth/authService';

export const authLink = setContext(async () => {
  const token = await getAccessToken();
  if (!token) return {};

  return { token };
}).concat(
  new ApolloLink((operation, forward) => {
    const { token } = operation.getContext();
    operation.setContext(() => ({
      headers: {
        Authorization: `Bearer ${token}` ?? '',
      },
    }));
    return forward(operation);
  }),
);
