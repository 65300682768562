import { Container } from '@mui/material';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import ManagerMenu from '../components/managerMenus/ManagerMenu';
import TaskList from '../components/tasks/taskLists/TaskList';
import strings from './ManagerTasksPage.strings';

const ManagerTasksPage: FC = () => {
  const { userId } = useParams<{ userId: string }>();

  return (
    <Layout pageName={strings.daily_tasks} showBackButton>
      <Container>
        <ManagerMenu userId={userId} />
        <TaskList userId={userId} />
      </Container>
    </Layout>
  );
};

export default ManagerTasksPage;
