import { Container } from '@mui/material';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import OrgAdmins from '../components/admins/OrgAdmins';
import Layout from '../components/Layout';
import useQueryApi from '../hooks/useQueryApi';
import { GET_ORGANIZATION, IOrganizationData } from '../services/organizations/organizationQueries';
import strings from './OrgAdminsPage.strings';

const OrgAdminsPage: FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, loading } = useQueryApi<IOrganizationData>(GET_ORGANIZATION, {
    variables: { id: organizationId },
  });

  const title = loading
    ? ''
    : strings.formatString(strings.org_admins, data?.organization.name ?? '').toString();

  return (
    <Layout pageName={title} showBackButton>
      <Container>
        {!loading && <OrgAdmins />}
      </Container>
    </Layout>
  );
};

export default OrgAdminsPage;
