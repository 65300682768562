import { Container } from '@mui/material';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import Studios from '../components/studios/Studios';
import useQueryApi from '../hooks/useQueryApi';
import { GET_ORGANIZATION, IOrganizationData } from '../services/organizations/organizationQueries';
import strings from './OrganizationStudios.strings';

const OrganizationStudiosPage: FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, loading } = useQueryApi<IOrganizationData>(GET_ORGANIZATION, {
    variables: { id: organizationId },
  });

  const title = loading
    ? ''
    : strings.formatString(strings.studios, data?.organization.name ?? '').toString();

  return (
    <Layout
      pageName={title}
      showBackButton
    >
      <Container>
        {!loading && <Studios organizationId={organizationId} />}
      </Container>
    </Layout>
  );
};

export default OrganizationStudiosPage;
