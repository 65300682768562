import { gql } from '@apollo/client';
import { ITask, ITaskDetail } from './taskTypes';

export interface ITasksData {
  userTasks: ITask[]
}

export const USER_TASK_DETAIL_FIELDS = gql`
    fragment userTaskDetailFields on UserTask {
      id
      startTime
      endTime
      date
      title
      description
      comment
      status
      completedOn
      assignedToId
    }
`;

export const GET_MY_TASKS_FOR_DATE = gql`
  query ($where: UserTaskFilterInput){
    userTasks (where: $where, order: { startTime: ASC }) {
      id
      startTime
      endTime
      date
      title
      status
    }
  }
`;

export interface ITaskDetailData {
  userTask: ITaskDetail
}

export const GET_TASK_DETAIL = gql`
  query ($id: UUID!){
    userTask (id: $id) {
      ...userTaskDetailFields
    }
  }
  ${USER_TASK_DETAIL_FIELDS}
`;
