import React, { FunctionComponent } from 'react';
import ConfirmationDialog from './ConfirmationDialog';
import { strings } from './DeleteConfirmation.strings';

export interface IProps {
  open: boolean,
  subjectTitle?: string,
  subject: string,
  onClose: () => void,
  onDelete: () => void
}

const DeleteConfirmation: FunctionComponent<IProps> = (props: IProps) => (
  <ConfirmationDialog
    title={`${strings.delete} ${!props.subjectTitle ? props.subject : props.subjectTitle}`}
    text={strings.formatString(strings.delete_text, props.subject).toString()}
    onClose={props.onClose}
    confirmButtonLabel={strings.delete}
    onConfirm={props.onDelete}
    open={props.open}
  />
);

DeleteConfirmation.defaultProps = {
  subjectTitle: undefined,
};

export default DeleteConfirmation;
