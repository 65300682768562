import { Container } from '@mui/material';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import UpdateStudio from '../components/studios/UpdateStudio';
import strings from './UpdateStudioPage.strings';

const UpdateStudioPage: FC = () => (
  <Layout pageName={strings.update_studio} showBackButton>
    <Container>
      <UpdateStudio />
    </Container>
  </Layout>
);

export default UpdateStudioPage;
