import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    title: 'Title',
    time: 'Time',
    the_schedule: 'the schedule',
    all_future: 'all future tasks ({0}) that have not been completed and don\'t have any saved comments',
    WEEKLY: 'Days Of Week',
    MONTHLY: 'Day of month',
    ONEOFF: 'Date',
  },
});
