import { gql } from '@apollo/client';
import { TASK_SCHEDULE_FIELDS } from './taskScheduleQueries';

export const ADD_USER_TASK_SCHEDULE = gql`
  mutation addUserTaskSchedule($userTaskScheduleInput: UserTaskScheduleInput!) {
    addUserTaskSchedule(userTaskScheduleInput: $userTaskScheduleInput) {
      ...taskScheduleFields
    }
  }
  ${TASK_SCHEDULE_FIELDS}
`;

export const DELETE_USER_TASK_SCHEDULE = gql`
  mutation deleteUserTaskSchedule($id: UUID!) {
    deleteUserTaskSchedule(id: $id)
  }
`;

export const UPDATE_USER_TASK_SCHEDULE = gql`
  mutation updateUserTaskSchedule($id: UUID!, $userTaskScheduleInput: UserTaskScheduleInput!) {
    updateUserTaskSchedule(id: $id, userTaskScheduleInput: $userTaskScheduleInput) {
      ...taskScheduleFields
    }
  }
  ${TASK_SCHEDULE_FIELDS}
`;
