import { GET_STUDIOS, IStudiosData } from '../../services/studios/studioQueries';
import { IStudio } from '../../services/studios/studioTypes';
import useQueryApi from '../useQueryApi';

export interface IUseStudiosQuery {
  studios?: IStudio[],
  loading: boolean,
  called: boolean,
}

export const useStudiosQuery = (organizationId?: string): IUseStudiosQuery => {
  const { loading, data, called } = useQueryApi<IStudiosData>(GET_STUDIOS, organizationId
    ? {
      variables: { where: { organizationId: { eq: organizationId } } },
      fetchPolicy: 'cache-and-network',
    }
    : { fetchPolicy: 'cache-and-network' });

  return {
    studios: data?.studios,
    loading,
    called,
  };
};
