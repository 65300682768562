import { Button } from '@mui/material';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Close as CloseIcon } from '@mui/icons-material';
import { strings } from './CancelButton.strings';

const CancelButton: FC = () => {
  const history = useHistory();
  const onCancel = () => history.goBack();

  return (
    <Button
      color="primary"
      startIcon={<CloseIcon />}
      variant="outlined"
      onClick={onCancel}
    >
      {strings.cancel}
    </Button>
  );
};

export default CancelButton;
