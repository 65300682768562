import { gql } from '@apollo/client';
import { IUser } from './userTypes';

export interface IMeData {
  me: IUser,
}

export interface IUserData {
  user: IUser,
}

export const USER_FIELDS = gql`
  fragment userFields on User {
    id
    name
    email
    studioRole
    studioId
    organizationId
    deleted
  }
`;

export const GET_MY_PROFILE = gql`
  query {
    me {
      ...userFields
    }
  }
  ${USER_FIELDS}
`;

export interface IUsersData {
  users: IUser[],
}

export const GET_USERS = gql`
  query ($where: UserFilterInput) {
    users (where: $where, order: { name: ASC }) {
      ...userFields
    }
  }
  ${USER_FIELDS}
`;

export const GET_USER = gql`
  query ($id: UUID!) {
    user (id: $id) {
      ...userFields
    }
  }
  ${USER_FIELDS}
`;
