import { useState } from 'react';
import { GET_NOTIFICATIONS, INotificationsData, NOTIFICATIONS_TAKE } from '../../services/notifications/notificationQueries';
import { INotification } from '../../services/notifications/notificationTypes';
import useLazyQueryApi from '../useLazyQueryApi';

export interface INotificationsQuery {
  fetchNotifications: () => Promise<void>,
  fetchPreviousPage: () => Promise<void>,
  fetchNextPage: () => Promise<void>,
  hasNextPage: boolean,
  hasPreviousPage: boolean,
  notifications?: INotification[],
  loading: boolean,
  called: boolean,
}

type QueryVariables = {
  before?: string,
  after?: string,
  first?: number,
  last?: number,
};

export const useNotificationsQuery = (): INotificationsQuery => {
  const [currentVariables, setCurrentVariables] = useState<QueryVariables>();
  const [
    fetchNotificationsQuery,
    { loading, data, called },
  ] = useLazyQueryApi<
    INotificationsData,
    QueryVariables
  >(GET_NOTIFICATIONS, { fetchPolicy: 'cache-and-network', pollInterval: 30000 });

  const getVariables = (before?: string, after?: string) => (
    {
      before,
      after,
      first: !before ? NOTIFICATIONS_TAKE : undefined,
      last: before ? NOTIFICATIONS_TAKE : undefined,
    }
  );

  const fetchNotifications = async (): Promise<void> => {
    const variables = getVariables();
    setCurrentVariables(variables);

    await fetchNotificationsQuery({ variables });
  };

  const fetchPreviousPage = async (): Promise<void> => {
    if (!currentVariables) return;

    const variables = getVariables(
      data?.notifications?.pageInfo.startCursor,
    );
    setCurrentVariables(variables);
    await fetchNotificationsQuery({ variables });
  };

  const fetchNextPage = async (): Promise<void> => {
    if (!currentVariables) return;

    const variables = getVariables(
      undefined,
      data?.notifications?.pageInfo.endCursor,
    );
    setCurrentVariables(variables);
    await fetchNotificationsQuery({ variables });
  };

  return {
    fetchNotifications,
    fetchPreviousPage,
    fetchNextPage,
    notifications: data?.notifications?.nodes,
    hasNextPage: data?.notifications?.pageInfo.hasNextPage ?? false,
    hasPreviousPage: data?.notifications?.pageInfo.hasPreviousPage ?? false,
    loading,
    called,
  };
};
