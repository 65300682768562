import { gql } from '@apollo/client';
import { ORGANIZATION_FIELDS } from './organizationQueries';

export const ADD_ORGANIZATION = gql`
  mutation addOrganization($name: String!) {
    addOrganization(name: $name) {
      ...organizationFields
    }
  }
  ${ORGANIZATION_FIELDS}
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($updateOrganizationInput: UpdateOrganizationInput!) {
    updateOrganization(updateOrganizationInput: $updateOrganizationInput) {
      ...organizationFields
    }
  }
  ${ORGANIZATION_FIELDS}
`;
