import { Container } from '@mui/material';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import ManageTask from '../components/tasks/manage/manageTask/ManageTask';
import strings from './ManageTaskPage.strings';

const ManageTaskPage: FC = () => (
  <Layout pageName={strings.manage_task} showBackButton>
    <Container>
      <ManageTask />
    </Container>
  </Layout>
);

export default ManageTaskPage;
