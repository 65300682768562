export enum NotificationType {
  TASK_COMPLETED = 'TASK_COMPLETED',
  ADMIN_MESSAGE = 'ADMIN_MESSAGE',
}

export interface INotification {
  notificationType: NotificationType,
  isRead: boolean,
  content: { key: string, value: string }[]
  id: string,
  createdOn: string,
}

export interface IUnreadNotificationsCheck {
  id: string,
  hasUnread: boolean,
}

export type AddNotificationInput = {
  message: string,
  recipientId: string,
};
