import { gql } from '@apollo/client';
import { IConnection } from '../queries/queryTypes';
import { INotification, IUnreadNotificationsCheck } from './notificationTypes';

export const NOTIFICATIONS_TAKE = 10;

export interface INotificationsData {
  notifications: IConnection<INotification>,
}

export interface IUnreadNotificationsCheckData {
  unreadNotificationsCheck: IUnreadNotificationsCheck,
}

export const NOTIFICATION_FIELDS = gql`
  fragment notificationFields on Notification {
      id
      notificationType
      isRead
      createdOn
      content {
        key
        value
      }
    }
`;

export const GET_NOTIFICATIONS = gql`
  query ($before: String, $after: String, $first: Int, $last: Int) {
    notifications (before: $before, after: $after, first: $first, last: $last, order: { createdOn: DESC }) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        ...notificationFields
      }
    }
  }
  ${NOTIFICATION_FIELDS}
`;

export const GET_UNREAD_NOTIFICATIONS_CHECK = gql`
  query {
    unreadNotificationsCheck {
      id
      hasUnread
    }
  }
`;
