import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Box } from '@mui/system';
import { strings } from './TaskListHeading.strings';
import ClickDatePicker from '../../dateTime/ClickDatePicker';

export interface IProps {
  onDateSelected: (selectedDate: string) => void,
  selectedDate: string,
  completedTasksMessage: string,
}

const TaskListHeading: FC<IProps> = (props: IProps) => {
  const onChange = (date: DateTime | null) => {
    props.onDateSelected?.(date?.toISODate() ?? '');
  };

  return (
    <Box
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        '& input': {
          cursor: 'pointer',
        },
      }}
    >
      <Typography variant="h5">{`${strings.completed} ${props.completedTasksMessage}`}</Typography>
      <ClickDatePicker
        onChange={onChange}
        value={props.selectedDate}
      />
    </Box>
  );
};

export default TaskListHeading;
