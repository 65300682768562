import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    no_admins: 'No organization admins configured for this organization',
    add_admin: 'Add Organization Admin',
    delete_user: 'user',
    delete: 'the user and disable their access',
  },
});
