import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    no_managers: 'No manager configured for this studio',
    add_manager: 'Add Studio Manager',
    delete_user: 'user',
    delete: 'the user and disable their access',
  },
});
