import { useEffect } from 'react';
import { GET_USERS, IUsersData } from '../../services/users/userQueries';
import { IUser } from '../../services/users/userTypes';
import useLazyQueryApi from '../useLazyQueryApi';

export interface IStudioManagersQuery {
  studioManagers?: IUser[],
  loading: boolean,
  called: boolean,
  refetchManagers: () => void,
}

export const useStudioManagersQuery = (studioId: string): IStudioManagersQuery => {
  const [fetchManagersQuery, { loading, data, called }] = useLazyQueryApi<
    IUsersData,
    { where: { studioId: { eq: string }, deleted: { eq: boolean } } }
  >(GET_USERS, {
    fetchPolicy: 'cache-and-network',
  });
  const variables = {
    where: {
      studioId: { eq: studioId },
      deleted: { eq: false },
    },
  };

  useEffect(() => {
    fetchManagersQuery({ variables });
  }, []);

  const refetchManagers = (): void => {
    fetchManagersQuery({ variables });
  };

  return {
    studioManagers: data?.users,
    loading,
    called,
    refetchManagers,
  };
};
