import {
  Box,
  Button,
  IconButton,
  List, ListItem, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Paper,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Add, Edit } from '@mui/icons-material';
import useQueryApi from '../../hooks/useQueryApi';
import { RoutePaths } from '../../routing/Routes';
import { strings } from './Organizations.strings';
import { GET_ORGANIZATIONS, IOrganizationsData } from '../../services/organizations/organizationQueries';

const Organizations: FC = () => {
  const { loading, data } = useQueryApi<IOrganizationsData>(GET_ORGANIZATIONS);
  const { push } = useHistory();
  const [anchorEl, setAnchorEl] = useState<Element | undefined>();
  const [menuOrgId, setMenuOrgId] = useState<string>();

  if (loading) return null;

  const handleClose = () => {
    setAnchorEl(undefined);
    setMenuOrgId(undefined);
  };

  return (
    <Box
      sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 2,
        maxWidth: '800px',
      }}
    >
      <Box
        sx={{
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          color="primary"
          variant="outlined"
          startIcon={<Add />}
          onClick={() => push(RoutePaths.addOrganization)}
        >
          {strings.add_organization}
        </Button>
      </Box>
      <Paper>
        <List component="nav">
          {data?.organizations.map((organization) => (
            <ListItem
              key={organization.id}
              aria-controls="simple-menu"
              aria-haspopup="true"
              button
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setMenuOrgId(organization.id);
              }}
            >
              <ListItemText primary={organization.name} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="comments"
                  onClick={() => push(RoutePaths.updateOrganization(organization.id))}
                >
                  <Edit />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl ?? null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(handleClose)}
      >
        <MenuItem
          onClick={() => push(
            RoutePaths.organizationStudios(menuOrgId ?? ''),
          )}
        >
          {strings.manage_studios}
        </MenuItem>
        <MenuItem
          onClick={() => push(RoutePaths.orgAdmins(menuOrgId ?? ''))}
        >
          {strings.manage_admins}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Organizations;
