import React, { FC, useContext } from 'react';
import { Box, LinearProgress } from '@mui/material';
import { ProgressIndicatotStateContext } from '../../contexts/ProgressIndicatorProvider';

const ProgressIndicator: FC = () => {
  const showingProgress = useContext(ProgressIndicatotStateContext);

  if (!showingProgress) return null;

  return (
    <Box position="fixed" top={0} left={0} width="100%" zIndex="9999">
      <LinearProgress />
    </Box>
  );
};

export default ProgressIndicator;
