import {
  Box,
  Button, IconButton, List, ListItem, ListItemSecondaryAction,
  ListItemText, Paper, Typography,
} from '@mui/material';
import { Add, RemoveCircleOutline } from '@mui/icons-material';
import React, { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useMutationApi from '../../hooks/useMutationApi';
import { useOrgAdminsQuery } from '../../hooks/users/useOrgAdminsQuery';
import { RoutePaths } from '../../routing/Routes';
import { DELETE_USER } from '../../services/users/userMutations';
import DeleteConfirmation from '../widgets/confirmations/DeleteConfirmation';
import { strings } from './OrgAdmins.strings';

const OrgAdmins: FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { push } = useHistory();
  const { loading, admins, refetchManagers } = useOrgAdminsQuery(organizationId);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [userIdToDelete, setUserIdToDelete] = useState<string>();
  const [deleteUser] = useMutationApi(DELETE_USER);

  if (loading || !admins) return null;

  const onUserDelete = async () => {
    await deleteUser({ variables: { id: userIdToDelete } });
    setDeleteOpen(false);
    refetchManagers();
  };

  return (
    <>
      <Box
        sx={{
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 2,
          maxWidth: '800px',
        }}
      >
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            startIcon={<Add />}
            onClick={() => push(RoutePaths.addOrgAdmin(organizationId))}
          >
            {strings.add_admin}
          </Button>
        </Box>
        {!admins.length
          ? <Typography sx={{ mt: 2 }}>{strings.no_admins}</Typography>
          : (
            <Paper>
              <List component="nav">
                {admins?.map((orgAdmin) => (
                  <ListItem
                    key={orgAdmin.id}
                    button
                  >
                    <ListItemText primary={orgAdmin.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="remove-user"
                        component="span"
                        onClick={() => {
                          setUserIdToDelete(orgAdmin.id);
                          setDeleteOpen(true);
                        }}
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
      </Box>
      <DeleteConfirmation
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDelete={onUserDelete}
        subject={strings.delete}
        subjectTitle={strings.delete_user}
      />
    </>
  );
};

export default OrgAdmins;
