import {
  Box,
  IconButton, Paper, Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { ArrowForwardIos, CheckCircleOutline, RadioButtonUnchecked } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { getTaskTimeString } from '../../../services/tasks/taskService';
import { ITask, TaskStatus } from '../../../services/tasks/taskTypes';
import { RoutePaths } from '../../../routing/Routes';
import ButtonsContainer from '../../navigation/ButtonsContainer';

export interface IProps {
  task: ITask,
}

const TaskListItem: FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(RoutePaths.taskDetail(props.task.id));
  };

  return (
    <Paper
      sx={{
        cursor: 'pointer',
        marginTop: 2,
        padding: 4,
        display: 'flex',
        flexDirection: 'row',
      }}
      onClick={handleClick}
    >
      <Box sx={{ flexGrow: 1, minWidth: 0 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 2,
            alignItems: 'center',
            color: (props?.task.status === TaskStatus.COMPLETED
              ? 'success.main'
              : 'secondary.main'),
          }}
        >
          <Typography variant="h6">
            {getTaskTimeString(props.task)}
          </Typography>
          {props?.task.status === TaskStatus.COMPLETED
            ? <CheckCircleOutline sx={{ ml: 1, color: 'inherit' }} />
            : <RadioButtonUnchecked sx={{ ml: 1, color: 'inherit' }} />}
        </Box>
        <Typography variant="h5">{props.task.title}</Typography>
      </Box>
      <ButtonsContainer>
        <IconButton component="span">
          <ArrowForwardIos />
        </IconButton>
      </ButtonsContainer>
    </Paper>
  );
};

export default TaskListItem;
