import { Button, TextField } from '@mui/material';
import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { SaveAlt, Done } from '@mui/icons-material';
import { strings } from './TaskCommentForm.strings';
import { ITaskDetail, ITaskValuesInput } from '../../../services/tasks/taskTypes';
import { useTaskMutation } from '../../../hooks/tasks/useTaskMutation';
import CancelButton from '../../navigation/CancelButton';
import ButtonsContainer from '../../navigation/ButtonsContainer';

type Inputs = {
  comment: string,
}

export interface IProps {
  task: ITaskDetail,
}

const TaskCommentForm: FC<IProps> = (props: IProps) => {
  const { completeTask, saveTaskValues } = useTaskMutation();
  const {
    handleSubmit, control, formState,
  } = useForm<Inputs>();

  const onSubmit = async (submitType: 'SAVE' | 'COMPLETE', data: Inputs) => {
    const vals: ITaskValuesInput = { id: props.task?.id, comment: data.comment };
    if (submitType === 'COMPLETE') {
      await completeTask(vals);
    } else {
      await saveTaskValues(vals);
    }
  };

  return (
    <form>
      <Controller
        name="comment"
        control={control}
        defaultValue={props?.task.comment}
        render={({ field }) => (
          <TextField
            sx={{ width: '100%', mb: 4 }}
            {...field}
            label={strings.comment}
            multiline
            variant="outlined"
          />
        )}
      />
      <ButtonsContainer>
        <CancelButton />
        <Button
          color="secondary"
          startIcon={<SaveAlt />}
          variant="outlined"
          disabled={formState.isSubmitting}
          onClick={handleSubmit((data) => onSubmit('SAVE', data))}
        >
          {strings.save}
        </Button>
        <Button
          color="secondary"
          startIcon={<Done />}
          variant="contained"
          disabled={formState.isSubmitting}
          onClick={handleSubmit((data) => onSubmit('COMPLETE', data))}
        >
          {strings.complete}
        </Button>
      </ButtonsContainer>
    </form>
  );
};

export default TaskCommentForm;
