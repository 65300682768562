import { IconButton, Paper, Typography } from '@mui/material';
import { MailOutline, RemoveCircleOutline } from '@mui/icons-material';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useLazyQueryApi from '../../hooks/useLazyQueryApi';
import useMutationApi from '../../hooks/useMutationApi';
import useQueryApi from '../../hooks/useQueryApi';
import { RoutePaths } from '../../routing/Routes';
import { DELETE_USER } from '../../services/users/userMutations';
import {
  GET_MY_PROFILE, GET_USER, IMeData, IUserData,
} from '../../services/users/userQueries';
import { isOrganizationAdmin } from '../../services/users/userService';
import DeleteConfirmation from '../widgets/confirmations/DeleteConfirmation';
import { strings } from './ManagerMenu.strings';
import ManagerMessageDialog from './ManagerMessageDialog';

export interface IProps {
  userId: string,
  messageText?: string,
}

const ManagerMenu: FC<IProps> = (props: IProps) => {
  const { push } = useHistory();
  const { data: myProfileData, loading: myProfileLoading } = useQueryApi<IMeData>(GET_MY_PROFILE);
  const [sendMessageOpen, setSendMessageOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [
    loadUser,
    { loading, data },
  ] = useLazyQueryApi<IUserData>(
    GET_USER,
    { variables: { id: props.userId } },
  );
  const [deleteUser] = useMutationApi(DELETE_USER);

  useEffect(() => {
    if (isOrganizationAdmin(myProfileData?.me)) loadUser();
  }, [myProfileData]);

  if (loading || myProfileLoading) return null;

  if (!isOrganizationAdmin(myProfileData?.me)) return null;

  const onUserDelete = async () => {
    await deleteUser({ variables: { id: props.userId } });
    setDeleteOpen(false);
    push(RoutePaths.studios);
  };

  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 2,
          paddingTop: 1,
          paddingBottom: 1,
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <Typography variant="h6">{data?.user.name}</Typography>
        <div>
          <IconButton aria-label="remove-user" component="span" onClick={() => setDeleteOpen(true)}>
            <RemoveCircleOutline />
          </IconButton>
          <IconButton aria-label="send-message" component="span" onClick={() => setSendMessageOpen(true)}>
            <MailOutline />
          </IconButton>
        </div>
      </Paper>
      <ManagerMessageDialog
        open={sendMessageOpen}
        user={data?.user}
        onClose={() => setSendMessageOpen(false)}
        initialText={props.messageText}
      />
      <DeleteConfirmation
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDelete={onUserDelete}
        subject={strings.delete}
        subjectTitle={strings.delete_user}
      />
    </>
  );
};

ManagerMenu.defaultProps = {
  messageText: undefined,
};

export default ManagerMenu;
