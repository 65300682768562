import {
  Badge,
  List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  Assignment, Store, Notifications,
} from '@mui/icons-material';
import { RoutePaths } from '../../routing/Routes';
import strings from './MenuList.strings';
import useQueryApi from '../../hooks/useQueryApi';
import { GET_MY_PROFILE, IMeData } from '../../services/users/userQueries';
import { GET_UNREAD_NOTIFICATIONS_CHECK, IUnreadNotificationsCheckData } from '../../services/notifications/notificationQueries';
import { isAdmin, isAnyAdmin, isOrganizationAdmin } from '../../services/users/userService';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}))(Badge);

export const MenuList: FC = () => {
  const { data, loading } = useQueryApi<IMeData>(GET_MY_PROFILE);
  const { data: notificationData } = useQueryApi<IUnreadNotificationsCheckData>(
    GET_UNREAD_NOTIFICATIONS_CHECK,
    { pollInterval: 30000 },
  );

  if (loading) return null;

  const admin = isAdmin(data?.me);
  const anyAdmin = isAnyAdmin(data?.me);
  const organizationAdmin = isOrganizationAdmin(data?.me);

  return (
    <List>

      {admin && (
      <ListItem
        button
        component={Link}
        to={RoutePaths.organizations}
      >
        <ListItemIcon>
          <Store />
        </ListItemIcon>
        <ListItemText>
          {strings.organizations}
        </ListItemText>
      </ListItem>
      )}

      {organizationAdmin && (
      <ListItem
        button
        component={Link}
        to={RoutePaths.studios}
      >
        <ListItemIcon>
          <Store />
        </ListItemIcon>
        <ListItemText>
          {strings.studios}
        </ListItemText>
      </ListItem>
      )}

      {organizationAdmin && (
      <ListItem
        button
        component={Link}
        to={RoutePaths.manageTasks}
      >
        <ListItemIcon>
          <Assignment />
        </ListItemIcon>
        <ListItemText>
          {strings.manage_tasks}
        </ListItemText>
      </ListItem>
      )}

      {!anyAdmin && (
      <ListItem
        button
        component={Link}
        to={RoutePaths.dailyTasks}
      >
        <ListItemIcon>
          <Assignment />
        </ListItemIcon>
        <ListItemText>
          {strings.tasks}
        </ListItemText>
      </ListItem>
      )}

      {!admin && (
      <ListItem
        button
        component={Link}
        to={RoutePaths.notifications}
      >
        <ListItemIcon>
          {notificationData
          && notificationData.unreadNotificationsCheck
          && notificationData.unreadNotificationsCheck.hasUnread
            ? (
              <StyledBadge
                overlap="circular"
                variant="dot"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <Notifications />
              </StyledBadge>
            ) : (
              <Notifications />
            )}
        </ListItemIcon>
        <ListItemText>
          {strings.notifications}
        </ListItemText>
      </ListItem>
      )}

    </List>
  );
};

export default MenuList;
