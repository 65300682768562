import { useEffect, useContext } from 'react';
import {
  OperationVariables, DocumentNode, QueryHookOptions,
  QueryResult, useQuery,
} from '@apollo/client';
import { FeedbackContext, FeedbackSeverity } from '../contexts/FeedbackProvider';
import useProgressIndicator from './useProgressIndicator';

export default function useQueryApi<
  TData = unknown,
  TVariables = OperationVariables
>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
  const queryResult = useQuery(query, options);
  const { showFeedback } = useContext(FeedbackContext);
  const { progressStarted, progressEnded } = useProgressIndicator();

  useEffect(() => {
    if (queryResult?.error) {
      showFeedback(queryResult.error.message, FeedbackSeverity.error);
    }
  }, [
    queryResult.error,
  ]);

  useEffect(() => {
    if (queryResult.loading) {
      progressStarted();
    } else {
      progressEnded();
    }
  }, [
    queryResult.loading,
  ]);

  return queryResult;
}
