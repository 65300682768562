import { Container } from '@mui/material';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import AddStudio from '../components/studios/AddStudio';
import strings from './AddStudioPage.strings';

const AddStudioPage: FC = () => (
  <Layout pageName={strings.add_studio} showBackButton>
    <Container>
      <AddStudio />
    </Container>
  </Layout>
);

export default AddStudioPage;
