import { Container } from '@mui/material';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import TaskList from '../components/tasks/taskLists/TaskList';
import strings from './DailyTasksPage.strings';

const DailyTasksPage: FC = () => (
  <Layout pageName={strings.daily_tasks}>
    <Container>
      <TaskList />
    </Container>
  </Layout>
);

export default DailyTasksPage;
