import { RoutePaths } from '../../routing/Routes';

const key = 'post-login-redirect';

export const remember = (url: string): void => {
  if (!window) return;

  sessionStorage.setItem(key, url);
};

export const getRedirectPath = (): string => {
  if (!window) return RoutePaths.dashboard;

  const url = sessionStorage.getItem(key);

  if (!url) return RoutePaths.dashboard;

  return url;
};
