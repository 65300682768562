import { GET_MY_TASKS_FOR_DATE, ITasksData } from '../../services/tasks/taskQueries';
import { ITask } from '../../services/tasks/taskTypes';
import useLazyQueryApi from '../useLazyQueryApi';

export interface IDailyTasksQuery {
  fetchTasks: (date: string, userId?: string) => void,
  tasks?: ITask[],
  loading: boolean,
  called: boolean,
}

export const useDailyTasksQuery = (): IDailyTasksQuery => {
  const [
    fetchTasksQuery,
    { loading, data, called },
  ] = useLazyQueryApi<
    ITasksData,
    { where: { date: { eq: string} } }
  >(GET_MY_TASKS_FOR_DATE, { fetchPolicy: 'cache-and-network' });

  const fetchTasks = (date: string, userId?: string) => {
    const variables = {
      where: {
        date: { eq: date },
      },
    };

    if (userId) {
      (variables.where as Record<string, unknown>).assignedToId = { eq: userId };
    }

    fetchTasksQuery({ variables });
  };

  return {
    fetchTasks,
    tasks: data?.userTasks,
    loading,
    called,
  };
};
