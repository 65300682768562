import React, { FC } from 'react';
import { Box, BoxProps } from '@mui/material';

const TabPanel: FC<Props> = (props: Props) => {
  const {
    children,
    index,
    value,
    ...boxProps
  } = props;

  return (
    <Box
      role="tabpanel"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      hidden={value !== index}
    >
      {value === index && children}
    </Box>
  );
};

type Props = BoxProps & {
    children?: React.ReactNode,
    index: number,
    value: number
};

TabPanel.defaultProps = {
  children: undefined,
};

export default TabPanel;
